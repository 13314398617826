import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Loader } from '@googlemaps/js-api-loader';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private googleMapsScriptLoaded = false;

  constructor() {}

  loadGoogleMaps(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.googleMapsScriptLoaded) {
        resolve();
        return;
      }

      const loader = new Loader({
        apiKey: environment.googleMapsApiKey,
        version: 'weekly',
      });

      await loader.importLibrary('places');
      await loader.importLibrary('geometry');

      resolve();
    });
  }
}
