export enum ProjectWorkStage {
  Stage1 = 0,
  Stage2A,
  Stage2B,
  Stage3A,
  Stage3B,
  Stage3C,
  Stage3D,
  Stage4A,
  Stage4B,
  Stage5,
}
