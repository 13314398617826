import * as i0 from '@angular/core';
import { Component, Input, HostBinding, NgModule } from '@angular/core';
import * as i1 from 'carbon-components-angular/i18n';
import { I18nModule } from 'carbon-components-angular/i18n';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';

/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { LoadingModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-loading--basic)
 */
const _c0 = (a0, a1, a2) => ({
  "cds--loading--small": a0,
  "cds--loading--stop": a1,
  "cds--loading-overlay--stop": a2
});
class Loading {
  constructor(i18n) {
    this.i18n = i18n;
    /**
     * Accessible title for the loading circle.
     * Defaults to the `LOADING.TITLE` value from the i18n service.
     */
    this.title = this.i18n.get().LOADING.TITLE;
    /**
     * set to `false` to stop the loading animation
     */
    this.isActive = true;
    /**
     * Specify the size of the button
     */
    this.size = "normal";
    /**
     * Set to `true` to make loader with an overlay.
     */
    this.overlay = false;
  }
}
Loading.ɵfac = function Loading_Factory(t) {
  return new (t || Loading)(i0.ɵɵdirectiveInject(i1.I18n));
};
Loading.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Loading,
  selectors: [["cds-loading"], ["ibm-loading"]],
  hostVars: 2,
  hostBindings: function Loading_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--loading-overlay", ctx.overlay);
    }
  },
  inputs: {
    title: "title",
    isActive: "isActive",
    size: "size",
    overlay: "overlay"
  },
  decls: 5,
  vars: 6,
  consts: [[1, "cds--loading", 3, "ngClass"], ["viewBox", "0 0 100 100", 1, "cds--loading__svg"], ["cx", "50%", "cy", "50%", "r", "44", 1, "cds--loading__stroke"]],
  template: function Loading_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelementStart(1, "svg", 1)(2, "title");
      i0.ɵɵtext(3);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(4, "circle", 2);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(2, _c0, ctx.size === "sm", !ctx.isActive && !ctx.overlay, !ctx.isActive && ctx.overlay));
      i0.ɵɵadvance(3);
      i0.ɵɵtextInterpolate(ctx.title);
    }
  },
  dependencies: [i2.NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Loading, [{
    type: Component,
    args: [{
      selector: "cds-loading, ibm-loading",
      template: `
		<div
			[ngClass]="{
				'cds--loading--small': size === 'sm',
				'cds--loading--stop': !isActive && !overlay,
				'cds--loading-overlay--stop': !isActive && overlay
			}"
			class="cds--loading">
			<svg class="cds--loading__svg" viewBox="0 0 100 100">
				<title>{{title}}</title>
				<circle class="cds--loading__stroke" cx="50%" cy="50%" r="44" />
			</svg>
		</div>
	`
    }]
  }], function () {
    return [{
      type: i1.I18n
    }];
  }, {
    title: [{
      type: Input
    }],
    isActive: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    overlay: [{
      type: Input
    }, {
      type: HostBinding,
      args: ["class.cds--loading-overlay"]
    }]
  });
})();
class LoadingModule {}
LoadingModule.ɵfac = function LoadingModule_Factory(t) {
  return new (t || LoadingModule)();
};
LoadingModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LoadingModule
});
LoadingModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, I18nModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingModule, [{
    type: NgModule,
    args: [{
      declarations: [Loading],
      exports: [Loading],
      imports: [CommonModule, I18nModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Loading, LoadingModule };
