import { ProjectWorkStage } from '../enums';

export const projectWorkStages: { key: ProjectWorkStage; label: string; description: string }[] = [
  {
    key: ProjectWorkStage.Stage1,
    label: 'Stage 1 - Strategic Definition',
    description: 'Strategic Definition',
  },
  {
    key: ProjectWorkStage.Stage2A,
    label: 'Stage 2A - Concept Masterplan',
    description: 'Concept Masterplan',
  },
  {
    key: ProjectWorkStage.Stage2B,
    label: 'Stage 2B - Detailed Masterplan',
    description: 'Detailed Masterplan',
  },
  {
    key: ProjectWorkStage.Stage3A,
    label: 'Stage 3A - Concept Design',
    description: 'Concept Design',
  },
  {
    key: ProjectWorkStage.Stage3B,
    label: 'Stage 3B - Developed Design',
    description: 'Developed Design',
  },
  {
    key: ProjectWorkStage.Stage3C,
    label: 'Stage 3C - Detailed Design',
    description: 'Detailed Design',
  },
  {
    key: ProjectWorkStage.Stage3D,
    label: 'Stage 3D - Tendering Stage',
    description: 'Tendering Stage and IFC',
  },
  {
    key: ProjectWorkStage.Stage4A,
    label: 'Stage 4A - Construction',
    description: 'Mobilization & Construction',
  },
  {
    key: ProjectWorkStage.Stage4B,
    label: 'Stage 4B - Handover and Close out',
    description: 'Testing & Commissioning. Handover and Close-out',
  },
  {
    key: ProjectWorkStage.Stage5,
    label: 'Stage 5 - Operations and Maintenance',
    description: 'Testing & Commissioning. Handover and Close-out',
  },
];
