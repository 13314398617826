import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { PROJECT_SECTIONS_ENDPOINT } from '../constants';
import { PartsFilteringDto, ProjectSectionDto, UpdateProjectSectionDto } from '../models';
import { PartsDto } from '../models/sections/parts-dto';
@Injectable()
export class ProjectSectionsService {
  private readonly _projectSectionsUrl: string = PROJECT_SECTIONS_ENDPOINT;

  constructor(private _apiService: ApiService) {}

  getProjectSectionById(id: string): Observable<ProjectSectionDto> {
    return this._apiService.get<ProjectSectionDto>(`${this._projectSectionsUrl}/${id}`);
  }

  updateProjectSection(projectSectionDto: UpdateProjectSectionDto): Observable<void> {
    return this._apiService.post<void>(`${this._projectSectionsUrl}`, projectSectionDto);
  }

  getPartsPreview(partsDto: PartsFilteringDto) : Observable<PartsDto[]> {
    return this._apiService.post<PartsDto[]>(`${this._projectSectionsUrl}/Parts`, partsDto);
  }
}
