import * as i0 from '@angular/core';
import { Directive, Input, HostBinding, TemplateRef, Component, ViewChild, ContentChild, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from 'carbon-components-angular/icon';
import { IconModule } from 'carbon-components-angular/icon';

/**
 * A directive for applying styling to an input element.
 *
 * Example:
 *
 * ```html
 * <input cdsText/>
 * ```
 *
 * See the [vanilla carbon docs](http://www.carbondesignsystem.com/components/text-input/code) for more detail.
 */
const _c0 = ["wrapper"];
function TextareaLabelComponent_1_ng_template_0_Template(rf, ctx) {}
function TextareaLabelComponent_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TextareaLabelComponent_1_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.labelTemplate);
  }
}
function TextareaLabelComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function TextareaLabelComponent__svg_svg_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 11);
  }
}
function TextareaLabelComponent__svg_svg_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 12);
  }
}
function TextareaLabelComponent_8_ng_template_0_Template(rf, ctx) {}
function TextareaLabelComponent_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TextareaLabelComponent_8_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r6.textAreaTemplate);
  }
}
function TextareaLabelComponent_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 1);
  }
}
function TextareaLabelComponent_div_11_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r14 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r14.helperText);
  }
}
function TextareaLabelComponent_div_11_2_ng_template_0_Template(rf, ctx) {}
function TextareaLabelComponent_div_11_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TextareaLabelComponent_div_11_2_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r15 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r15.helperText);
  }
}
const _c1 = a0 => ({
  "cds--form__helper-text--disabled": a0
});
function TextareaLabelComponent_div_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵtemplate(1, TextareaLabelComponent_div_11_ng_container_1_Template, 2, 1, "ng-container", 14)(2, TextareaLabelComponent_div_11_2_Template, 1, 1, null, 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c1, ctx_r9.disabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r9.isTemplate(ctx_r9.helperText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r9.isTemplate(ctx_r9.helperText));
  }
}
function TextareaLabelComponent_div_12_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r17 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r17.invalidText);
  }
}
function TextareaLabelComponent_div_12_2_ng_template_0_Template(rf, ctx) {}
function TextareaLabelComponent_div_12_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TextareaLabelComponent_div_12_2_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r18 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r18.invalidText);
  }
}
function TextareaLabelComponent_div_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵtemplate(1, TextareaLabelComponent_div_12_ng_container_1_Template, 2, 1, "ng-container", 14)(2, TextareaLabelComponent_div_12_2_Template, 1, 1, null, 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r10.isTemplate(ctx_r10.invalidText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r10.isTemplate(ctx_r10.invalidText));
  }
}
function TextareaLabelComponent_div_13_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r20 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r20.warnText);
  }
}
function TextareaLabelComponent_div_13_2_ng_template_0_Template(rf, ctx) {}
function TextareaLabelComponent_div_13_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TextareaLabelComponent_div_13_2_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r21 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r21.warnText);
  }
}
function TextareaLabelComponent_div_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵtemplate(1, TextareaLabelComponent_div_13_ng_container_1_Template, 2, 1, "ng-container", 14)(2, TextareaLabelComponent_div_13_2_Template, 1, 1, null, 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r11.isTemplate(ctx_r11.warnText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r11.isTemplate(ctx_r11.warnText));
  }
}
const _c2 = ["*", [["textarea"]]];
const _c3 = (a0, a1) => ({
  "cds--label--disabled": a0,
  "cds--skeleton": a1
});
const _c4 = a0 => ({
  "cds--text-input__field-wrapper--warning": a0
});
const _c5 = ["*", "textarea"];
function TextInputLabelComponent_1_ng_template_0_Template(rf, ctx) {}
function TextInputLabelComponent_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TextInputLabelComponent_1_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.labelTemplate);
  }
}
function TextInputLabelComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function TextInputLabelComponent__svg_svg_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 11);
  }
}
function TextInputLabelComponent__svg_svg_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 12);
  }
}
function TextInputLabelComponent_8_ng_template_0_Template(rf, ctx) {}
function TextInputLabelComponent_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TextInputLabelComponent_8_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r6.textInputTemplate);
  }
}
function TextInputLabelComponent_ng_template_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 1);
  }
}
function TextInputLabelComponent_div_11_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r14 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r14.helperText);
  }
}
function TextInputLabelComponent_div_11_2_ng_template_0_Template(rf, ctx) {}
function TextInputLabelComponent_div_11_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TextInputLabelComponent_div_11_2_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r15 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r15.helperText);
  }
}
function TextInputLabelComponent_div_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵtemplate(1, TextInputLabelComponent_div_11_ng_container_1_Template, 2, 1, "ng-container", 14)(2, TextInputLabelComponent_div_11_2_Template, 1, 1, null, 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r9 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c1, ctx_r9.disabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r9.isTemplate(ctx_r9.helperText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r9.isTemplate(ctx_r9.helperText));
  }
}
function TextInputLabelComponent_div_12_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r17 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r17.invalidText);
  }
}
function TextInputLabelComponent_div_12_2_ng_template_0_Template(rf, ctx) {}
function TextInputLabelComponent_div_12_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TextInputLabelComponent_div_12_2_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r18 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r18.invalidText);
  }
}
function TextInputLabelComponent_div_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵtemplate(1, TextInputLabelComponent_div_12_ng_container_1_Template, 2, 1, "ng-container", 14)(2, TextInputLabelComponent_div_12_2_Template, 1, 1, null, 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r10.isTemplate(ctx_r10.invalidText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r10.isTemplate(ctx_r10.invalidText));
  }
}
function TextInputLabelComponent_div_13_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r20 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r20.warnText);
  }
}
function TextInputLabelComponent_div_13_2_ng_template_0_Template(rf, ctx) {}
function TextInputLabelComponent_div_13_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TextInputLabelComponent_div_13_2_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r21 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r21.warnText);
  }
}
function TextInputLabelComponent_div_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵtemplate(1, TextInputLabelComponent_div_13_ng_container_1_Template, 2, 1, "ng-container", 14)(2, TextInputLabelComponent_div_13_2_Template, 1, 1, null, 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r11 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r11.isTemplate(ctx_r11.warnText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r11.isTemplate(ctx_r11.warnText));
  }
}
const _c6 = ["*", [["input", "type", "text"], ["div"]]];
const _c7 = ["*", "input[type=text],div"];
function Label_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function Label_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 1);
  }
}
function Label_ng_container_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "cds-textarea-label", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    const _r3 = i0.ɵɵreference(3);
    const _r1 = i0.ɵɵreference(1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("labelInputID", ctx_r4.labelInputID)("disabled", ctx_r4.disabled)("skeleton", ctx_r4.skeleton)("helperText", ctx_r4.helperText)("invalid", ctx_r4.invalid)("invalidText", ctx_r4.invalidText)("warn", ctx_r4.warn)("warnText", ctx_r4.warnText)("ariaLabel", ctx_r4.ariaLabel)("labelTemplate", _r3)("textAreaTemplate", _r1);
  }
}
function Label_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "cds-text-label", 7);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    const _r3 = i0.ɵɵreference(3);
    const _r1 = i0.ɵɵreference(1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("labelInputID", ctx_r5.labelInputID)("disabled", ctx_r5.disabled)("skeleton", ctx_r5.skeleton)("helperText", ctx_r5.helperText)("invalid", ctx_r5.invalid)("invalidText", ctx_r5.invalidText)("warn", ctx_r5.warn)("warnText", ctx_r5.warnText)("ariaLabel", ctx_r5.ariaLabel)("labelTemplate", _r3)("textInputTemplate", _r1);
  }
}
function Label_ng_container_7_ng_template_1_Template(rf, ctx) {}
function Label_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Label_ng_container_7_ng_template_1_Template, 0, 0, "ng-template", 8);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _r8 = i0.ɵɵreference(9);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r8);
  }
}
function Label_ng_template_8_ng_template_1_Template(rf, ctx) {}
function Label_ng_template_8__svg_svg_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 16);
  }
}
function Label_ng_template_8__svg_svg_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 17);
  }
}
function Label_ng_template_8_ng_template_6_Template(rf, ctx) {}
function Label_ng_template_8_div_7_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r18 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r18.helperText);
  }
}
function Label_ng_template_8_div_7_2_ng_template_0_Template(rf, ctx) {}
function Label_ng_template_8_div_7_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Label_ng_template_8_div_7_2_ng_template_0_Template, 0, 0, "ng-template", 8);
  }
  if (rf & 2) {
    const ctx_r19 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r19.helperText);
  }
}
function Label_ng_template_8_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 18);
    i0.ɵɵtemplate(1, Label_ng_template_8_div_7_ng_container_1_Template, 2, 1, "ng-container", 19)(2, Label_ng_template_8_div_7_2_Template, 1, 1, null, 19);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r15 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c1, ctx_r15.disabled));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r15.isTemplate(ctx_r15.helperText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r15.isTemplate(ctx_r15.helperText));
  }
}
function Label_ng_template_8_div_8_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r21 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r21.invalidText);
  }
}
function Label_ng_template_8_div_8_2_ng_template_0_Template(rf, ctx) {}
function Label_ng_template_8_div_8_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Label_ng_template_8_div_8_2_ng_template_0_Template, 0, 0, "ng-template", 8);
  }
  if (rf & 2) {
    const ctx_r22 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r22.invalidText);
  }
}
function Label_ng_template_8_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 20);
    i0.ɵɵtemplate(1, Label_ng_template_8_div_8_ng_container_1_Template, 2, 1, "ng-container", 19)(2, Label_ng_template_8_div_8_2_Template, 1, 1, null, 19);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r16 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r16.isTemplate(ctx_r16.invalidText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r16.isTemplate(ctx_r16.invalidText));
  }
}
function Label_ng_template_8_div_9_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r24 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r24.warnText);
  }
}
function Label_ng_template_8_div_9_2_ng_template_0_Template(rf, ctx) {}
function Label_ng_template_8_div_9_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Label_ng_template_8_div_9_2_ng_template_0_Template, 0, 0, "ng-template", 8);
  }
  if (rf & 2) {
    const ctx_r25 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r25.warnText);
  }
}
function Label_ng_template_8_div_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 20);
    i0.ɵɵtemplate(1, Label_ng_template_8_div_9_ng_container_1_Template, 2, 1, "ng-container", 19)(2, Label_ng_template_8_div_9_2_Template, 1, 1, null, 19);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r17 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r17.isTemplate(ctx_r17.warnText));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r17.isTemplate(ctx_r17.warnText));
  }
}
function Label_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 9);
    i0.ɵɵtemplate(1, Label_ng_template_8_ng_template_1_Template, 0, 0, "ng-template", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "div", 10, 11);
    i0.ɵɵtemplate(4, Label_ng_template_8__svg_svg_4_Template, 1, 0, "svg", 12)(5, Label_ng_template_8__svg_svg_5_Template, 1, 0, "svg", 13)(6, Label_ng_template_8_ng_template_6_Template, 0, 0, "ng-template", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(7, Label_ng_template_8_div_7_Template, 3, 5, "div", 14)(8, Label_ng_template_8_div_8_Template, 3, 2, "div", 15)(9, Label_ng_template_8_div_9_Template, 3, 2, "div", 15);
  }
  if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext();
    const _r3 = i0.ɵɵreference(3);
    const _r1 = i0.ɵɵreference(1);
    i0.ɵɵproperty("for", ctx_r7.labelInputID)("ngClass", i0.ɵɵpureFunction2(12, _c3, ctx_r7.disabled, ctx_r7.skeleton));
    i0.ɵɵattribute("aria-label", ctx_r7.ariaLabel);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(15, _c4, ctx_r7.warn));
    i0.ɵɵattribute("data-invalid", ctx_r7.invalid ? true : null);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r7.warn && ctx_r7.invalid);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r7.invalid && ctx_r7.warn);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", _r1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r7.skeleton && ctx_r7.helperText && !ctx_r7.invalid && !ctx_r7.warn);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r7.warn && ctx_r7.invalid);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r7.invalid && ctx_r7.warn);
  }
}
const _c8 = [[["input"], ["textarea"], ["div"]], "*"];
const _c9 = ["input,textarea,div", "*"];
class TextInput {
  constructor() {
    /**
     * @deprecated since v5 - Use `cdsLayer` directive instead
     * `light` or `dark` input theme
     */
    this.theme = "dark";
    /**
     * Input field render size
     */
    this.size = "md";
    this.inputClass = true;
    this.invalid = false;
    this.warn = false;
    this.skeleton = false;
  }
  /**
   * @todo - remove `cds--text-input--${size}` classes in v12
   */
  get isSizeSm() {
    return this.size === "sm";
  }
  get isSizeMd() {
    return this.size === "md";
  }
  get isSizelg() {
    return this.size === "lg";
  }
  // Size
  get sizeSm() {
    return this.size === "sm";
  }
  get sizeMd() {
    return this.size === "md";
  }
  get sizelg() {
    return this.size === "lg";
  }
  get isLightTheme() {
    return this.theme === "light";
  }
}
TextInput.ɵfac = function TextInput_Factory(t) {
  return new (t || TextInput)();
};
TextInput.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TextInput,
  selectors: [["", "cdsText", ""], ["", "ibmText", ""]],
  hostVars: 22,
  hostBindings: function TextInput_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--text-input", ctx.inputClass)("cds--text-input--sm", ctx.isSizeSm)("cds--text-input--md", ctx.isSizeMd)("cds--text-input--lg", ctx.isSizelg)("cds--layout--size-sm", ctx.sizeSm)("cds--layout--size-md", ctx.sizeMd)("cds--layout--size-lg", ctx.sizelg)("cds--text-input--invalid", ctx.invalid)("cds--text-input__field-wrapper--warning", ctx.warn)("cds--skeleton", ctx.skeleton)("cds--text-input--light", ctx.isLightTheme);
    }
  },
  inputs: {
    theme: "theme",
    size: "size",
    invalid: "invalid",
    warn: "warn",
    skeleton: "skeleton"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextInput, [{
    type: Directive,
    args: [{
      selector: "[cdsText], [ibmText]"
    }]
  }], null, {
    theme: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    inputClass: [{
      type: HostBinding,
      args: ["class.cds--text-input"]
    }],
    isSizeSm: [{
      type: HostBinding,
      args: ["class.cds--text-input--sm"]
    }],
    isSizeMd: [{
      type: HostBinding,
      args: ["class.cds--text-input--md"]
    }],
    isSizelg: [{
      type: HostBinding,
      args: ["class.cds--text-input--lg"]
    }],
    sizeSm: [{
      type: HostBinding,
      args: ["class.cds--layout--size-sm"]
    }],
    sizeMd: [{
      type: HostBinding,
      args: ["class.cds--layout--size-md"]
    }],
    sizelg: [{
      type: HostBinding,
      args: ["class.cds--layout--size-lg"]
    }],
    invalid: [{
      type: HostBinding,
      args: ["class.cds--text-input--invalid"]
    }, {
      type: Input
    }],
    warn: [{
      type: HostBinding,
      args: ["class.cds--text-input__field-wrapper--warning"]
    }, {
      type: Input
    }],
    skeleton: [{
      type: HostBinding,
      args: ["class.cds--skeleton"]
    }, {
      type: Input
    }],
    isLightTheme: [{
      type: HostBinding,
      args: ["class.cds--text-input--light"]
    }]
  });
})();

/**
 * A directive for applying styling to a textarea element.
 *
 * Example:
 *
 * ```html
 * <textarea cdsTextArea></textarea>
 * ```
 *
 * See the [vanilla carbon docs](http://www.carbondesignsystem.com/components/text-input/code) for more detail.
 */
class TextArea {
  constructor() {
    /**
     * @deprecated since v5 - Use `cdsLayer` directive instead
     * `light` or `dark` input theme
     */
    this.theme = "dark";
    this.baseClass = true;
    this.invalid = false;
    this.skeleton = false;
  }
  get isLightTheme() {
    return this.theme === "light";
  }
}
TextArea.ɵfac = function TextArea_Factory(t) {
  return new (t || TextArea)();
};
TextArea.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TextArea,
  selectors: [["", "cdsTextArea", ""], ["", "ibmTextArea", ""]],
  hostVars: 8,
  hostBindings: function TextArea_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--text-area", ctx.baseClass)("cds--text-area--invalid", ctx.invalid)("cds--skeleton", ctx.skeleton)("cds--text-area--light", ctx.isLightTheme);
    }
  },
  inputs: {
    theme: "theme",
    invalid: "invalid",
    skeleton: "skeleton"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextArea, [{
    type: Directive,
    args: [{
      selector: "[cdsTextArea], [ibmTextArea]"
    }]
  }], null, {
    theme: [{
      type: Input
    }],
    baseClass: [{
      type: HostBinding,
      args: ["class.cds--text-area"]
    }],
    invalid: [{
      type: HostBinding,
      args: ["class.cds--text-area--invalid"]
    }, {
      type: Input
    }],
    skeleton: [{
      type: HostBinding,
      args: ["class.cds--skeleton"]
    }, {
      type: Input
    }],
    isLightTheme: [{
      type: HostBinding,
      args: ["class.cds--text-area--light"]
    }]
  });
})();

/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { InputModule } from 'carbon-components-angular';
 * ```
 *
 * ```html
 * <cds-textarea-label>
 * 	Label
 * 	<textarea cdsTextArea class="textarea-field">
 * </cds-textarea-label>
 * ```
 *
 * [See demo](../../?path=/story/components-input-text-area--basic)
 */
class TextareaLabelComponent {
  /**
   * Creates an instance of Label.
   */
  constructor(changeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
    /**
     * The id of the input item associated with the `Label`. This value is also used to associate the `Label` with
     * its input counterpart through the 'for' attribute.
    */
    this.labelInputID = "ibm-textarea-" + TextareaLabelComponent.labelCounter;
    /**
     * Set to `true` for a disabled label.
     */
    this.disabled = false;
    /**
     * Set to `true` for a loading label.
     */
    this.skeleton = false;
    /**
     * Set to `true` for an invalid label component.
     */
    this.invalid = false;
    /**
      * Set to `true` to show a warning (contents set by warningText)
      */
    this.warn = false;
    this.labelClass = true;
  }
  /**
   * Sets the id on the input item associated with the `Label`.
   */
  ngAfterViewInit() {
    if (this.wrapper) {
      // Prioritize setting id to `textarea` over div
      const inputElement = this.wrapper.nativeElement.querySelector("textarea");
      if (inputElement) {
        // avoid overriding ids already set by the user reuse it instead
        if (inputElement.id) {
          this.labelInputID = inputElement.id;
          this.changeDetectorRef.detectChanges();
        }
        inputElement.setAttribute("id", this.labelInputID);
        return;
      }
      const divElement = this.wrapper.nativeElement.querySelector("div");
      if (divElement) {
        if (divElement.id) {
          this.labelInputID = divElement.id;
          this.changeDetectorRef.detectChanges();
        }
        divElement.setAttribute("id", this.labelInputID);
      }
    }
  }
  isTemplate(value) {
    return value instanceof TemplateRef;
  }
}
/**
 * Used to build the id of the input item associated with the `Label`.
 */
TextareaLabelComponent.labelCounter = 0;
TextareaLabelComponent.ɵfac = function TextareaLabelComponent_Factory(t) {
  return new (t || TextareaLabelComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
TextareaLabelComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TextareaLabelComponent,
  selectors: [["cds-textarea-label"], ["ibm-textarea-label"]],
  contentQueries: function TextareaLabelComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TextArea, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textArea = _t.first);
    }
  },
  viewQuery: function TextareaLabelComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.wrapper = _t.first);
    }
  },
  hostVars: 2,
  hostBindings: function TextareaLabelComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--form-item", ctx.labelClass);
    }
  },
  inputs: {
    labelInputID: "labelInputID",
    disabled: "disabled",
    skeleton: "skeleton",
    labelTemplate: "labelTemplate",
    textAreaTemplate: "textAreaTemplate",
    helperText: "helperText",
    invalidText: "invalidText",
    invalid: "invalid",
    warn: "warn",
    warnText: "warnText",
    ariaLabel: "ariaLabel"
  },
  ngContentSelectors: _c5,
  decls: 14,
  vars: 19,
  consts: [[1, "cds--label", 3, "for", "ngClass"], [4, "ngIf", "ngIfElse"], ["labelContent", ""], [1, "cds--text-area__wrapper", 3, "ngClass"], ["wrapper", ""], ["cdsIcon", "warning--filled", "size", "16", "class", "cds--text-area__invalid-icon", 4, "ngIf"], ["cdsIcon", "warning--alt--filled", "size", "16", "class", "cds--text-input__invalid-icon cds--text-input__invalid-icon--warning", 4, "ngIf"], ["textAreaContent", ""], ["class", "cds--form__helper-text", 3, "ngClass", 4, "ngIf"], ["class", "cds--form-requirement", 4, "ngIf"], [3, "ngTemplateOutlet"], ["cdsIcon", "warning--filled", "size", "16", 1, "cds--text-area__invalid-icon"], ["cdsIcon", "warning--alt--filled", "size", "16", 1, "cds--text-input__invalid-icon", "cds--text-input__invalid-icon--warning"], [1, "cds--form__helper-text", 3, "ngClass"], [4, "ngIf"], [1, "cds--form-requirement"]],
  template: function TextareaLabelComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c2);
      i0.ɵɵelementStart(0, "label", 0);
      i0.ɵɵtemplate(1, TextareaLabelComponent_1_Template, 1, 1, null, 1)(2, TextareaLabelComponent_ng_template_2_Template, 1, 0, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "div", 3, 4);
      i0.ɵɵtemplate(6, TextareaLabelComponent__svg_svg_6_Template, 1, 0, "svg", 5)(7, TextareaLabelComponent__svg_svg_7_Template, 1, 0, "svg", 6)(8, TextareaLabelComponent_8_Template, 1, 1, null, 1)(9, TextareaLabelComponent_ng_template_9_Template, 1, 0, "ng-template", null, 7, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(11, TextareaLabelComponent_div_11_Template, 3, 5, "div", 8)(12, TextareaLabelComponent_div_12_Template, 3, 2, "div", 9)(13, TextareaLabelComponent_div_13_Template, 3, 2, "div", 9);
    }
    if (rf & 2) {
      const _r2 = i0.ɵɵreference(3);
      const _r8 = i0.ɵɵreference(10);
      i0.ɵɵproperty("for", ctx.labelInputID)("ngClass", i0.ɵɵpureFunction2(14, _c3, ctx.disabled, ctx.skeleton));
      i0.ɵɵattribute("aria-label", ctx.ariaLabel);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.labelTemplate)("ngIfElse", _r2);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(17, _c4, ctx.warn));
      i0.ɵɵattribute("data-invalid", ctx.invalid ? true : null);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", !ctx.warn && ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.invalid && ctx.warn);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.textAreaTemplate)("ngIfElse", _r8);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", !ctx.skeleton && ctx.helperText && !ctx.invalid && !ctx.warn);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.warn && ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.invalid && ctx.warn);
    }
  },
  dependencies: [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i2.IconDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextareaLabelComponent, [{
    type: Component,
    args: [{
      selector: "cds-textarea-label, ibm-textarea-label",
      template: `
		<label
			[for]="labelInputID"
			[attr.aria-label]="ariaLabel"
			class="cds--label"
			[ngClass]="{
				'cds--label--disabled': disabled,
				'cds--skeleton': skeleton
			}">
			<ng-template *ngIf="labelTemplate; else labelContent" [ngTemplateOutlet]="labelTemplate"></ng-template>
			<ng-template #labelContent>
				<ng-content></ng-content>
			</ng-template>
		</label>
		<div
			class="cds--text-area__wrapper"
			[ngClass]="{
				'cds--text-input__field-wrapper--warning': warn
			}"
			[attr.data-invalid]="(invalid ? true : null)"
			#wrapper>
			<svg
				*ngIf="!warn && invalid"
				cdsIcon="warning--filled"
				size="16"
				class="cds--text-area__invalid-icon">
			</svg>
			<svg
				*ngIf="!invalid && warn"
				cdsIcon="warning--alt--filled"
				size="16"
				class="cds--text-input__invalid-icon cds--text-input__invalid-icon--warning">
			</svg>
			<ng-template *ngIf="textAreaTemplate; else textAreaContent" [ngTemplateOutlet]="textAreaTemplate"></ng-template>
			<ng-template #textAreaContent>
				<ng-content select="textarea"></ng-content>
			</ng-template>
		</div>
		<div
			*ngIf="!skeleton && helperText && !invalid && !warn"
			class="cds--form__helper-text"
			[ngClass]="{'cds--form__helper-text--disabled': disabled}">
			<ng-container *ngIf="!isTemplate(helperText)">{{helperText}}</ng-container>
			<ng-template *ngIf="isTemplate(helperText)" [ngTemplateOutlet]="helperText"></ng-template>
		</div>
		<div *ngIf="!warn && invalid" class="cds--form-requirement">
			<ng-container *ngIf="!isTemplate(invalidText)">{{invalidText}}</ng-container>
			<ng-template *ngIf="isTemplate(invalidText)" [ngTemplateOutlet]="invalidText"></ng-template>
		</div>
		<div *ngIf="!invalid && warn" class="cds--form-requirement">
			<ng-container *ngIf="!isTemplate(warnText)">{{warnText}}</ng-container>
			<ng-template *ngIf="isTemplate(warnText)" [ngTemplateOutlet]="warnText"></ng-template>
		</div>
	`
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, {
    labelInputID: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    skeleton: [{
      type: Input
    }],
    labelTemplate: [{
      type: Input
    }],
    textAreaTemplate: [{
      type: Input
    }],
    helperText: [{
      type: Input
    }],
    invalidText: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    warn: [{
      type: Input
    }],
    warnText: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    wrapper: [{
      type: ViewChild,
      args: ["wrapper", {
        static: false
      }]
    }],
    textArea: [{
      type: ContentChild,
      args: [TextArea, {
        static: false
      }]
    }],
    labelClass: [{
      type: HostBinding,
      args: ["class.cds--form-item"]
    }]
  });
})();

/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { InputModule } from 'carbon-components-angular';
 * ```
 *
 * ```html
 * <cds-text-label>
 * 	Label
 * 	<input cdsText type="text" class="input-field">
 * </cds-text-label>
 * ```
 *
 * [See demo](../../?path=/story/components-input--basic)
 */
class TextInputLabelComponent {
  /**
   * Creates an instance of Label.
   */
  constructor(changeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
    /**
     * The id of the input item associated with the `Label`. This value is also used to associate the `Label` with
     * its input counterpart through the 'for' attribute.
    */
    this.labelInputID = "ibm-text-input-" + TextInputLabelComponent.labelCounter++;
    /**
     * Set to `true` for a disabled label.
     */
    this.disabled = false;
    /**
     * Set to `true` for a loading label.
     */
    this.skeleton = false;
    /**
     * Set to `true` for an invalid label component.
     */
    this.invalid = false;
    /**
      * Set to `true` to show a warning (contents set by warningText)
      */
    this.warn = false;
    this.labelClass = true;
  }
  /**
   * Sets the id on the input item associated with the `Label`.
   */
  ngAfterViewInit() {
    if (this.wrapper) {
      // Prioritize setting id to `input` over div
      const inputElement = this.wrapper.nativeElement.querySelector("input");
      if (inputElement) {
        // avoid overriding ids already set by the user reuse it instead
        if (inputElement.id) {
          this.labelInputID = inputElement.id;
          this.changeDetectorRef.detectChanges();
        }
        inputElement.setAttribute("id", this.labelInputID);
        return;
      }
      const divElement = this.wrapper.nativeElement.querySelector("div");
      if (divElement) {
        if (divElement.id) {
          this.labelInputID = divElement.id;
          this.changeDetectorRef.detectChanges();
        }
        divElement.setAttribute("id", this.labelInputID);
      }
    }
  }
  isTemplate(value) {
    return value instanceof TemplateRef;
  }
}
/**
 * Used to build the id of the input item associated with the `Label`.
 */
TextInputLabelComponent.labelCounter = 0;
TextInputLabelComponent.ɵfac = function TextInputLabelComponent_Factory(t) {
  return new (t || TextInputLabelComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
TextInputLabelComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TextInputLabelComponent,
  selectors: [["cds-text-label"], ["ibm-text-label"]],
  viewQuery: function TextInputLabelComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.wrapper = _t.first);
    }
  },
  hostVars: 2,
  hostBindings: function TextInputLabelComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--form-item", ctx.labelClass);
    }
  },
  inputs: {
    labelInputID: "labelInputID",
    disabled: "disabled",
    skeleton: "skeleton",
    labelTemplate: "labelTemplate",
    textInputTemplate: "textInputTemplate",
    helperText: "helperText",
    invalidText: "invalidText",
    invalid: "invalid",
    warn: "warn",
    warnText: "warnText",
    ariaLabel: "ariaLabel"
  },
  ngContentSelectors: _c7,
  decls: 14,
  vars: 19,
  consts: [[1, "cds--label", 3, "for", "ngClass"], [4, "ngIf", "ngIfElse"], ["labelContent", ""], [1, "cds--text-input__field-wrapper", 3, "ngClass"], ["wrapper", ""], ["cdsIcon", "warning--filled", "size", "16", "class", "cds--text-input__invalid-icon", 4, "ngIf"], ["cdsIcon", "warning--alt--filled", "size", "16", "class", "cds--text-input__invalid-icon cds--text-input__invalid-icon--warning", 4, "ngIf"], ["textInputContent", ""], ["class", "cds--form__helper-text", 3, "ngClass", 4, "ngIf"], ["class", "cds--form-requirement", 4, "ngIf"], [3, "ngTemplateOutlet"], ["cdsIcon", "warning--filled", "size", "16", 1, "cds--text-input__invalid-icon"], ["cdsIcon", "warning--alt--filled", "size", "16", 1, "cds--text-input__invalid-icon", "cds--text-input__invalid-icon--warning"], [1, "cds--form__helper-text", 3, "ngClass"], [4, "ngIf"], [1, "cds--form-requirement"]],
  template: function TextInputLabelComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c6);
      i0.ɵɵelementStart(0, "label", 0);
      i0.ɵɵtemplate(1, TextInputLabelComponent_1_Template, 1, 1, null, 1)(2, TextInputLabelComponent_ng_template_2_Template, 1, 0, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(4, "div", 3, 4);
      i0.ɵɵtemplate(6, TextInputLabelComponent__svg_svg_6_Template, 1, 0, "svg", 5)(7, TextInputLabelComponent__svg_svg_7_Template, 1, 0, "svg", 6)(8, TextInputLabelComponent_8_Template, 1, 1, null, 1)(9, TextInputLabelComponent_ng_template_9_Template, 1, 0, "ng-template", null, 7, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(11, TextInputLabelComponent_div_11_Template, 3, 5, "div", 8)(12, TextInputLabelComponent_div_12_Template, 3, 2, "div", 9)(13, TextInputLabelComponent_div_13_Template, 3, 2, "div", 9);
    }
    if (rf & 2) {
      const _r2 = i0.ɵɵreference(3);
      const _r8 = i0.ɵɵreference(10);
      i0.ɵɵproperty("for", ctx.labelInputID)("ngClass", i0.ɵɵpureFunction2(14, _c3, ctx.disabled, ctx.skeleton));
      i0.ɵɵattribute("aria-label", ctx.ariaLabel);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.labelTemplate)("ngIfElse", _r2);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(17, _c4, ctx.warn));
      i0.ɵɵattribute("data-invalid", ctx.invalid ? true : null);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", !ctx.warn && ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.invalid && ctx.warn);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.textInputTemplate)("ngIfElse", _r8);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", !ctx.skeleton && ctx.helperText && !ctx.invalid && !ctx.warn);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.warn && ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.invalid && ctx.warn);
    }
  },
  dependencies: [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i2.IconDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextInputLabelComponent, [{
    type: Component,
    args: [{
      selector: "cds-text-label, ibm-text-label",
      template: `
		<label
			[for]="labelInputID"
			[attr.aria-label]="ariaLabel"
			class="cds--label"
			[ngClass]="{
				'cds--label--disabled': disabled,
				'cds--skeleton': skeleton
			}">
			<ng-template *ngIf="labelTemplate; else labelContent" [ngTemplateOutlet]="labelTemplate"></ng-template>
			<ng-template #labelContent>
				<ng-content></ng-content>
			</ng-template>
		</label>
		<div
			class="cds--text-input__field-wrapper"
			[ngClass]="{
				'cds--text-input__field-wrapper--warning': warn
			}"
			[attr.data-invalid]="(invalid ? true : null)"
			#wrapper>
			<svg
				*ngIf="!warn && invalid"
				cdsIcon="warning--filled"
				size="16"
				class="cds--text-input__invalid-icon">
			</svg>
			<svg
				*ngIf="!invalid && warn"
				cdsIcon="warning--alt--filled"
				size="16"
				class="cds--text-input__invalid-icon cds--text-input__invalid-icon--warning">
			</svg>
			<ng-template *ngIf="textInputTemplate; else textInputContent" [ngTemplateOutlet]="textInputTemplate"></ng-template>
			<ng-template #textInputContent>
				<ng-content select="input[type=text],div"></ng-content>
			</ng-template>
		</div>
		<div
			*ngIf="!skeleton && helperText && !invalid && !warn"
			class="cds--form__helper-text"
			[ngClass]="{'cds--form__helper-text--disabled': disabled}">
			<ng-container *ngIf="!isTemplate(helperText)">{{helperText}}</ng-container>
			<ng-template *ngIf="isTemplate(helperText)" [ngTemplateOutlet]="helperText"></ng-template>
		</div>
		<div *ngIf="!warn && invalid" class="cds--form-requirement">
			<ng-container *ngIf="!isTemplate(invalidText)">{{invalidText}}</ng-container>
			<ng-template *ngIf="isTemplate(invalidText)" [ngTemplateOutlet]="invalidText"></ng-template>
		</div>
		<div *ngIf="!invalid && warn" class="cds--form-requirement">
			<ng-container *ngIf="!isTemplate(warnText)">{{warnText}}</ng-container>
			<ng-template *ngIf="isTemplate(warnText)" [ngTemplateOutlet]="warnText"></ng-template>
		</div>
	`
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, {
    labelInputID: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    skeleton: [{
      type: Input
    }],
    labelTemplate: [{
      type: Input
    }],
    textInputTemplate: [{
      type: Input
    }],
    helperText: [{
      type: Input
    }],
    invalidText: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    warn: [{
      type: Input
    }],
    warnText: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    wrapper: [{
      type: ViewChild,
      args: ["wrapper", {
        static: false
      }]
    }],
    labelClass: [{
      type: HostBinding,
      args: ["class.cds--form-item"]
    }]
  });
})();

/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { InputModule } from 'carbon-components-angular';
 * ```
 *
 * To prevent attribute drilling, use `ibm-text-label` or `ibm-textarea-label` components
 *
 * ```html
 * <cds-label>
 * 	Label
 * 	<input cdsText type="text" class="input-field">
 * </cds-label>
 * ```
 *
 * [See demo](../../?path=/story/components-input--basic)
 */
class Label {
  /**
   * Creates an instance of Label.
   */
  constructor(changeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
    /**
     * The id of the input item associated with the `Label`. This value is also used to associate the `Label` with
     * its input counterpart through the 'for' attribute.
    */
    this.labelInputID = `cds-label-${Label.labelCounter++}`;
    /**
     * Set to `true` for disabled state.
     */
    this.disabled = false;
    /**
     * Set to `true` for a loading label.
     */
    this.skeleton = false;
    /**
     * Set to `true` for an invalid label component.
     */
    this.invalid = false;
    /**
      * Set to `true` to show a warning (contents set by warningText)
      */
    this.warn = false;
  }
  get labelClass() {
    return this.type === undefined;
  }
  /**
   * Update wrapper class if a textarea is hosted.
   */
  ngAfterContentInit() {
    if (this.textArea) {
      this.type = "TextArea";
    } else if (this.textInput) {
      this.type = "TextInput";
    }
  }
  /**
   * Sets the id on the input item associated with the `Label`.
   */
  ngAfterViewInit() {
    // Will only be called when `default` template is being used
    if (this.wrapper) {
      // Prioritize setting id to `input` & `textarea` over div
      const inputElement = this.wrapper.nativeElement.querySelector("input,textarea");
      if (inputElement) {
        // avoid overriding ids already set by the user reuse it instead
        if (inputElement.id) {
          this.labelInputID = inputElement.id;
          this.changeDetectorRef.detectChanges();
        }
        inputElement.setAttribute("id", this.labelInputID);
        return;
      }
      const divElement = this.wrapper.nativeElement.querySelector("div");
      if (divElement) {
        if (divElement.id) {
          this.labelInputID = divElement.id;
          this.changeDetectorRef.detectChanges();
        }
        divElement.setAttribute("id", this.labelInputID);
      }
    }
  }
  isTemplate(value) {
    return value instanceof TemplateRef;
  }
}
/**
 * Used to build the id of the input item associated with the `Label`.
 */
Label.labelCounter = 0;
Label.ɵfac = function Label_Factory(t) {
  return new (t || Label)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
Label.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Label,
  selectors: [["cds-label"], ["ibm-label"]],
  contentQueries: function Label_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TextArea, 5);
      i0.ɵɵcontentQuery(dirIndex, TextInput, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textArea = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textInput = _t.first);
    }
  },
  viewQuery: function Label_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.wrapper = _t.first);
    }
  },
  hostVars: 2,
  hostBindings: function Label_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--form-item", ctx.labelClass);
    }
  },
  inputs: {
    labelInputID: "labelInputID",
    disabled: "disabled",
    skeleton: "skeleton",
    helperText: "helperText",
    invalidText: "invalidText",
    invalid: "invalid",
    warn: "warn",
    warnText: "warnText",
    ariaLabel: "ariaLabel"
  },
  ngContentSelectors: _c9,
  decls: 10,
  vars: 3,
  consts: [["inputContentTemplate", ""], ["labelContentTemplate", ""], [3, "ngSwitch"], [4, "ngSwitchCase"], [4, "ngSwitchDefault"], ["default", ""], [3, "labelInputID", "disabled", "skeleton", "helperText", "invalid", "invalidText", "warn", "warnText", "ariaLabel", "labelTemplate", "textAreaTemplate"], [3, "labelInputID", "disabled", "skeleton", "helperText", "invalid", "invalidText", "warn", "warnText", "ariaLabel", "labelTemplate", "textInputTemplate"], [3, "ngTemplateOutlet"], [1, "cds--label", 3, "for", "ngClass"], [1, "cds--text-input__field-wrapper", 3, "ngClass"], ["wrapper", ""], ["cdsIcon", "warning--filled", "size", "16", "class", "cds--text-input__invalid-icon", 4, "ngIf"], ["cdsIcon", "warning--alt--filled", "size", "16", "class", "cds--text-input__invalid-icon cds--text-input__invalid-icon--warning", 4, "ngIf"], ["class", "cds--form__helper-text", 3, "ngClass", 4, "ngIf"], ["class", "cds--form-requirement", 4, "ngIf"], ["cdsIcon", "warning--filled", "size", "16", 1, "cds--text-input__invalid-icon"], ["cdsIcon", "warning--alt--filled", "size", "16", 1, "cds--text-input__invalid-icon", "cds--text-input__invalid-icon--warning"], [1, "cds--form__helper-text", 3, "ngClass"], [4, "ngIf"], [1, "cds--form-requirement"]],
  template: function Label_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c8);
      i0.ɵɵtemplate(0, Label_ng_template_0_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(2, Label_ng_template_2_Template, 1, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementContainerStart(4, 2);
      i0.ɵɵtemplate(5, Label_ng_container_5_Template, 2, 11, "ng-container", 3)(6, Label_ng_container_6_Template, 2, 11, "ng-container", 3)(7, Label_ng_container_7_Template, 2, 1, "ng-container", 4);
      i0.ɵɵelementContainerEnd();
      i0.ɵɵtemplate(8, Label_ng_template_8_Template, 10, 17, "ng-template", null, 5, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("ngSwitch", ctx.type);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngSwitchCase", "TextArea");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngSwitchCase", "TextInput");
    }
  },
  dependencies: [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgSwitch, i1.NgSwitchCase, i1.NgSwitchDefault, i2.IconDirective, TextareaLabelComponent, TextInputLabelComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Label, [{
    type: Component,
    args: [{
      selector: "cds-label, ibm-label",
      template: `
		<ng-template #inputContentTemplate>
			<ng-content select="input,textarea,div"></ng-content>
		</ng-template>

		<ng-template #labelContentTemplate>
			<ng-content></ng-content>
		</ng-template>

		<ng-container [ngSwitch]="type">
			<ng-container *ngSwitchCase="'TextArea'">
				<cds-textarea-label
					[labelInputID]="labelInputID"
					[disabled]="disabled"
					[skeleton]="skeleton"
					[helperText]="helperText"
					[invalid]="invalid"
					[invalidText]="invalidText"
					[warn]="warn"
					[warnText]="warnText"
					[ariaLabel]="ariaLabel"
					[labelTemplate]="labelContentTemplate"
					[textAreaTemplate]="inputContentTemplate">
				</cds-textarea-label>
			</ng-container>
			<ng-container *ngSwitchCase="'TextInput'">
				<cds-text-label
					[labelInputID]="labelInputID"
					[disabled]="disabled"
					[skeleton]="skeleton"
					[helperText]="helperText"
					[invalid]="invalid"
					[invalidText]="invalidText"
					[warn]="warn"
					[warnText]="warnText"
					[ariaLabel]="ariaLabel"
					[labelTemplate]="labelContentTemplate"
					[textInputTemplate]="inputContentTemplate">
				</cds-text-label>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<ng-template [ngTemplateOutlet]="default"></ng-template>
			</ng-container>
		</ng-container>

		<ng-template #default>
			<label
				[for]="labelInputID"
				[attr.aria-label]="ariaLabel"
				class="cds--label"
				[ngClass]="{
					'cds--label--disabled': disabled,
					'cds--skeleton': skeleton
				}">
				<ng-template [ngTemplateOutlet]="labelContentTemplate"></ng-template>
			</label>
			<div
				class="cds--text-input__field-wrapper"
				[ngClass]="{
					'cds--text-input__field-wrapper--warning': warn
				}"
				[attr.data-invalid]="(invalid ? true : null)"
				#wrapper>
				<svg
					*ngIf="!warn && invalid"
					cdsIcon="warning--filled"
					size="16"
					class="cds--text-input__invalid-icon">
				</svg>
				<svg
					*ngIf="!invalid && warn"
					cdsIcon="warning--alt--filled"
					size="16"
					class="cds--text-input__invalid-icon cds--text-input__invalid-icon--warning">
				</svg>
				<ng-template [ngTemplateOutlet]="inputContentTemplate"></ng-template>
			</div>
			<div
				*ngIf="!skeleton && helperText && !invalid && !warn"
				class="cds--form__helper-text"
				[ngClass]="{'cds--form__helper-text--disabled': disabled}">
				<ng-container *ngIf="!isTemplate(helperText)">{{helperText}}</ng-container>
				<ng-template *ngIf="isTemplate(helperText)" [ngTemplateOutlet]="helperText"></ng-template>
			</div>
			<div *ngIf="!warn && invalid" class="cds--form-requirement">
				<ng-container *ngIf="!isTemplate(invalidText)">{{invalidText}}</ng-container>
				<ng-template *ngIf="isTemplate(invalidText)" [ngTemplateOutlet]="invalidText"></ng-template>
			</div>
			<div *ngIf="!invalid && warn" class="cds--form-requirement">
				<ng-container *ngIf="!isTemplate(warnText)">{{warnText}}</ng-container>
				<ng-template *ngIf="isTemplate(warnText)" [ngTemplateOutlet]="warnText"></ng-template>
			</div>
		</ng-template>
	`
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }];
  }, {
    labelInputID: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    skeleton: [{
      type: Input
    }],
    helperText: [{
      type: Input
    }],
    invalidText: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    warn: [{
      type: Input
    }],
    warnText: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    wrapper: [{
      type: ViewChild,
      args: ["wrapper"]
    }],
    textArea: [{
      type: ContentChild,
      args: [TextArea]
    }],
    textInput: [{
      type: ContentChild,
      args: [TextInput, {
        static: false
      }]
    }],
    labelClass: [{
      type: HostBinding,
      args: ["class.cds--form-item"]
    }]
  });
})();

// modules
class InputModule {}
InputModule.ɵfac = function InputModule_Factory(t) {
  return new (t || InputModule)();
};
InputModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: InputModule
});
InputModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, FormsModule, IconModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputModule, [{
    type: NgModule,
    args: [{
      declarations: [Label, TextInput, TextArea, TextareaLabelComponent, TextInputLabelComponent],
      exports: [Label, TextareaLabelComponent, TextInputLabelComponent, TextInput, TextArea],
      imports: [CommonModule, FormsModule, IconModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { InputModule, Label, TextArea, TextInput, TextInputLabelComponent, TextareaLabelComponent };
