import { ProjectValidationComment } from './../models/project/project-validation-comment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PROJECTS_ENDPOINT } from '../constants';
import { CreateProject, Project } from '../models';
import { ProjectValidation } from '../models/project/project-validation';
import { ApiService } from './api.service';
import { ProjectExport } from '../models/project/project-export';
import { KoPExport } from '../models/project/kop-export';

@Injectable()
export class ProjectService {
  private _projectsEndpoint = PROJECTS_ENDPOINT;

  constructor(private _apiService: ApiService) {}

  getAllProjects(): Observable<Project[]> {
    return this._apiService.get<Project[]>(`${this._projectsEndpoint}`);
  }

  getProjectById(id: string, version: number | null = null): Observable<Project> {
    return this._apiService.get<Project>(`${this._projectsEndpoint}/GetProject`, { id, version });
  }

  updateProject(project: Project): Observable<Project> {
    return this._apiService.put<Project>(`${this._projectsEndpoint}`, project);
  }

  createProject(createProjectDto: CreateProject): Observable<Project> {
    return this._apiService.post<Project>(this._projectsEndpoint, createProjectDto);
  }

  getProjectValidationById(id: string): Observable<ProjectValidation> {
    return this._apiService.get<ProjectValidation>(`${this._projectsEndpoint}/validation`, { id });
  }

  approveValidation(id: string): Observable<Project> {
    return this._apiService.put<Project>(`${this._projectsEndpoint}/approve-validation/?id=${id}`);
  }

  exportProject(projectExport: ProjectExport): Observable<Project> {
    return this._apiService.post<Project>(`${this._projectsEndpoint}/export`, projectExport);
  }

  getProjectExport(projectId: string): Observable<ProjectExport> {
    return this._apiService.get<ProjectExport>(`${this._projectsEndpoint}/export/${projectId}`);
  }

  filteredKopsForProjectExport(projectExport: ProjectExport): Observable<KoPExport> {
    return this._apiService.post<KoPExport>(`${this._projectsEndpoint}/export/filter-kops`, projectExport);
  }

  validationComment(projectValidationComment: ProjectValidationComment): Observable<void> {
    return this._apiService.post<void>(`${this._projectsEndpoint}/validation-comment`, projectValidationComment);
  }

  archiveProject(id: string): Observable<void> {
    return this._apiService.delete<void>(`${this._projectsEndpoint}/?id=${id}`);
  }
}
