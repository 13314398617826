import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UserBasicInfo } from '../../features/authentication/models/UserBasicInfo';
import { USERS_ENDPOINT } from '../constants';

@Injectable()
export class UsersService {
  private _usersEndpoint = USERS_ENDPOINT;

  constructor(private _apiService: ApiService) {}

  getAllUsers(): Observable<UserBasicInfo[]> {
    return this._apiService.get<UserBasicInfo[]>(`${this._usersEndpoint}/GetAll`);
  }
}
