import * as i0 from '@angular/core';
import { TemplateRef, Component, HostBinding, Input, ViewChild, HostListener, NgModule } from '@angular/core';
import { PopoverContainer, PopoverModule } from 'carbon-components-angular/popover';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';

/**
 * Default tooltip configuration for components to populate missing interface attributes
 */
const _c0 = ["contentWrapper"];
function Tooltip_span_3_ng_container_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r3.description);
  }
}
function Tooltip_span_3_ng_container_1_3_ng_template_0_Template(rf, ctx) {}
function Tooltip_span_3_ng_container_1_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Tooltip_span_3_ng_container_1_3_ng_template_0_Template, 0, 0, "ng-template", 6);
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r4.description);
  }
}
function Tooltip_span_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "span", 4);
    i0.ɵɵtemplate(2, Tooltip_span_3_ng_container_1_ng_container_2_Template, 2, 1, "ng-container", 3)(3, Tooltip_span_3_ng_container_1_3_Template, 1, 1, null, 3);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(4, "span", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r2.isTemplate(ctx_r2.description));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.isTemplate(ctx_r2.description));
  }
}
function Tooltip_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 2);
    i0.ɵɵtemplate(1, Tooltip_span_3_ng_container_1_Template, 5, 2, "ng-container", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r1.id);
    i0.ɵɵattribute("aria-hidden", !ctx_r1.isOpen);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.disabled);
  }
}
const _c1 = ["*"];
function TooltipDefinition_span_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.description);
  }
}
function TooltipDefinition_span_2_3_ng_template_0_Template(rf, ctx) {}
function TooltipDefinition_span_2_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TooltipDefinition_span_2_3_ng_template_0_Template, 0, 0, "ng-template", 6);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.description);
  }
}
function TooltipDefinition_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 2)(1, "span", 3);
    i0.ɵɵtemplate(2, TooltipDefinition_span_2_ng_container_2_Template, 2, 1, "ng-container", 4)(3, TooltipDefinition_span_2_3_Template, 1, 1, null, 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(4, "span", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r0.id);
    i0.ɵɵattribute("aria-hidden", ctx_r0.isOpen);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !ctx_r0.isTemplate(ctx_r0.description));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.isTemplate(ctx_r0.description));
  }
}
const DEFAULT_TOOLTIP_CONFIG = {
  align: "bottom",
  caret: true,
  dropShadow: true,
  highContrast: true,
  isOpen: false,
  enterDelayMs: 100,
  leaveDelayMs: 300
};

/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { TooltipModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-tooltip--basic)
 */
class Tooltip extends PopoverContainer {
  constructor() {
    super();
    this.tooltipClass = true;
    this.id = `tooltip-${Tooltip.tooltipCount++}`;
    /**
     * Set delay before tooltip is shown
     */
    this.enterDelayMs = 100;
    /**
     * Set delay when tooltip disappears
     */
    this.leaveDelayMs = 300;
    /**
     * Prevent tooltip from showing, used by icon button
     */
    this.disabled = false;
    this.highContrast = true;
    this.dropShadow = false;
  }
  mouseenter(event) {
    setTimeout(() => {
      this.handleChange(true, event);
    }, this.enterDelayMs);
  }
  mouseleave(event) {
    setTimeout(() => {
      this.handleChange(false, event);
    }, this.leaveDelayMs);
  }
  hostkeys(event) {
    if (open && event.key === "Escape") {
      event.stopPropagation();
      this.handleChange(false, event);
    }
  }
  // We are not focusing on entire popover, only the trigger
  handleFocus(event) {
    this.handleChange(true, event);
  }
  handleFocusOut(event) {
    this.handleChange(false, event);
  }
  isTemplate(value) {
    return value instanceof TemplateRef;
  }
  /**
   * Check for any changes in the projected content & apply accessibility attribute if needed
   */
  ngAfterContentChecked() {
    if (this.wrapper) {
      const buttonElement = this.wrapper.nativeElement.querySelector("button");
      if (buttonElement && !buttonElement.getAttribute("aria-labelledby")) {
        buttonElement.setAttribute("aria-labelledby", this.id);
      }
    }
  }
}
Tooltip.tooltipCount = 0;
Tooltip.ɵfac = function Tooltip_Factory(t) {
  return new (t || Tooltip)();
};
Tooltip.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Tooltip,
  selectors: [["cds-tooltip"], ["ibm-tooltip"]],
  viewQuery: function Tooltip_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.wrapper = _t.first);
    }
  },
  hostVars: 2,
  hostBindings: function Tooltip_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("mouseenter", function Tooltip_mouseenter_HostBindingHandler($event) {
        return ctx.mouseenter($event);
      })("mouseleave", function Tooltip_mouseleave_HostBindingHandler($event) {
        return ctx.mouseleave($event);
      })("keyup", function Tooltip_keyup_HostBindingHandler($event) {
        return ctx.hostkeys($event);
      })("focusin", function Tooltip_focusin_HostBindingHandler($event) {
        return ctx.handleFocus($event);
      })("focusout", function Tooltip_focusout_HostBindingHandler($event) {
        return ctx.handleFocusOut($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵclassProp("cds--tooltip", ctx.tooltipClass);
    }
  },
  inputs: {
    id: "id",
    enterDelayMs: "enterDelayMs",
    leaveDelayMs: "leaveDelayMs",
    disabled: "disabled",
    description: "description"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c1,
  decls: 4,
  vars: 1,
  consts: [["contentWrapper", ""], ["class", "cds--popover", "role", "tooltip", 3, "id", 4, "ngIf"], ["role", "tooltip", 1, "cds--popover", 3, "id"], [4, "ngIf"], [1, "cds--popover-content", "cds--tooltip-content"], [1, "cds--popover-caret"], [3, "ngTemplateOutlet"]],
  template: function Tooltip_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "span", null, 0);
      i0.ɵɵprojection(2);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(3, Tooltip_span_3_Template, 2, 3, "span", 1);
    }
    if (rf & 2) {
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.description);
    }
  },
  dependencies: [i1.NgIf, i1.NgTemplateOutlet],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Tooltip, [{
    type: Component,
    args: [{
      selector: "cds-tooltip, ibm-tooltip",
      template: `
		<span #contentWrapper>
			<ng-content></ng-content>
		</span>
		<span
			*ngIf="description"
			class="cds--popover"
			[id]="id"
			[attr.aria-hidden]="!isOpen"
			role="tooltip">
			<ng-container *ngIf="!disabled">
				<span class="cds--popover-content cds--tooltip-content">
					<ng-container *ngIf="!isTemplate(description)">{{description}}</ng-container>
					<ng-template *ngIf="isTemplate(description)" [ngTemplateOutlet]="description"></ng-template>
				</span>
				<span class="cds--popover-caret"></span>
			</ng-container>
		</span>
	`
    }]
  }], function () {
    return [];
  }, {
    tooltipClass: [{
      type: HostBinding,
      args: ["class.cds--tooltip"]
    }],
    id: [{
      type: Input
    }],
    enterDelayMs: [{
      type: Input
    }],
    leaveDelayMs: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    description: [{
      type: Input
    }],
    wrapper: [{
      type: ViewChild,
      args: ["contentWrapper"]
    }],
    mouseenter: [{
      type: HostListener,
      args: ["mouseenter", ["$event"]]
    }],
    mouseleave: [{
      type: HostListener,
      args: ["mouseleave", ["$event"]]
    }],
    hostkeys: [{
      type: HostListener,
      args: ["keyup", ["$event"]]
    }],
    handleFocus: [{
      type: HostListener,
      args: ["focusin", ["$event"]]
    }],
    handleFocusOut: [{
      type: HostListener,
      args: ["focusout", ["$event"]]
    }]
  });
})();

/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { TooltipModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-tooltip-definition--basic)
 */
class TooltipDefinition extends PopoverContainer {
  constructor() {
    super();
    this.id = `tooltip-definition-${TooltipDefinition.tooltipCount++}`;
    /**
     * Override alignment options
     */
    this.align = "bottom";
    this.highContrast = true;
    this.dropShadow = false;
  }
  onBlur(event) {
    this.handleChange(false, event);
  }
  onClick(event) {
    this.handleChange(!this.isOpen, event);
  }
  hostkeys(event) {
    if (this.isOpen && event.key === "Escape") {
      event.stopPropagation();
      this.handleChange(false, event);
    }
  }
  mouseleave(event) {
    this.handleChange(false, event);
  }
  isTemplate(value) {
    return value instanceof TemplateRef;
  }
}
TooltipDefinition.tooltipCount = 0;
TooltipDefinition.ɵfac = function TooltipDefinition_Factory(t) {
  return new (t || TooltipDefinition)();
};
TooltipDefinition.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TooltipDefinition,
  selectors: [["cds-tooltip-definition"], ["ibm-tooltip-definition"]],
  hostBindings: function TooltipDefinition_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keyup", function TooltipDefinition_keyup_HostBindingHandler($event) {
        return ctx.hostkeys($event);
      })("mouseleave", function TooltipDefinition_mouseleave_HostBindingHandler($event) {
        return ctx.mouseleave($event);
      });
    }
  },
  inputs: {
    id: "id",
    align: "align",
    description: "description"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c1,
  decls: 3,
  vars: 3,
  consts: [["type", "button", 1, "cds--definition-term", 3, "blur", "click"], ["class", "cds--popover", "role", "tooltip", 3, "id", 4, "ngIf"], ["role", "tooltip", 1, "cds--popover", 3, "id"], [1, "cds--popover-content", "cds--definition-tooltip"], [4, "ngIf"], [1, "cds--popover-caret"], [3, "ngTemplateOutlet"]],
  template: function TooltipDefinition_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "button", 0);
      i0.ɵɵlistener("blur", function TooltipDefinition_Template_button_blur_0_listener($event) {
        return ctx.onBlur($event);
      })("click", function TooltipDefinition_Template_button_click_0_listener($event) {
        return ctx.onClick($event);
      });
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(2, TooltipDefinition_span_2_Template, 5, 4, "span", 1);
    }
    if (rf & 2) {
      i0.ɵɵattribute("aria-controls", ctx.id)("aria-expanded", ctx.isOpen);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.description);
    }
  },
  dependencies: [i1.NgIf, i1.NgTemplateOutlet],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipDefinition, [{
    type: Component,
    args: [{
      selector: "cds-tooltip-definition, ibm-tooltip-definition",
      template: `
		<button
			class="cds--definition-term"
			[attr.aria-controls]="id"
			[attr.aria-expanded]="isOpen"
			(blur)="onBlur($event)"
			(click)="onClick($event)"
			type="button">
			<ng-content></ng-content>
		</button>
		<span
			*ngIf="description"
			class="cds--popover"
			[id]="id"
			[attr.aria-hidden]="isOpen"
			role="tooltip">
			<span class="cds--popover-content cds--definition-tooltip">
				<ng-container *ngIf="!isTemplate(description)">{{description}}</ng-container>
				<ng-template *ngIf="isTemplate(description)" [ngTemplateOutlet]="description"></ng-template>
			</span>
			<span class="cds--popover-caret"></span>
		</span>
	`
    }]
  }], function () {
    return [];
  }, {
    id: [{
      type: Input
    }],
    align: [{
      type: Input
    }],
    description: [{
      type: Input
    }],
    hostkeys: [{
      type: HostListener,
      args: ["keyup", ["$event"]]
    }],
    mouseleave: [{
      type: HostListener,
      args: ["mouseleave", ["$event"]]
    }]
  });
})();
class TooltipModule {}
TooltipModule.ɵfac = function TooltipModule_Factory(t) {
  return new (t || TooltipModule)();
};
TooltipModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TooltipModule
});
TooltipModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, PopoverModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipModule, [{
    type: NgModule,
    args: [{
      declarations: [Tooltip, TooltipDefinition],
      exports: [Tooltip, TooltipDefinition],
      imports: [CommonModule, PopoverModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_TOOLTIP_CONFIG, Tooltip, TooltipDefinition, TooltipModule };
