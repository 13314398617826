import { ProjectStatus } from '../enums';

export const projectStatuses: { key: ProjectStatus; label: string; description: string }[] = [
  {
    key: ProjectStatus.ProposalDraft,
    label: 'Proposal - Draft',
    description:
      'We are in discussion with the proponent and clarifying items like location, budget, roles and responsibilities',
  },

  {
    key: ProjectStatus.ProposalDesign,
    label: 'Proposal - Design',
    description: 'Concept design undertaken by Mobility Team to prove viability of the project.',
  },

  {
    key: ProjectStatus.ProposalClosed,
    label: 'Proposal - Closed',
    description: 'The proposal has not been accepted because the opportunity is not viable to all stakeholders',
  },

  {
    key: ProjectStatus.ProjectInitiated,
    label: 'Project Initiated',
    description: 'The proposal is accepted and the relevant stages of ETSD are initiated',
  },

  {
    key: ProjectStatus.ProjectInProgressTendering,
    label: 'Project in Progress (Tendering)',
    description: 'The project is in tendering phase.',
  },

  {
    key: ProjectStatus.ProjectInProgressExecution,
    label: 'Project in Progress (Execution)',
    description: 'The project is in executing phase.',
  },

  {
    key: ProjectStatus.ProjectHandoverClosed,
    label: 'Project Handover/Closed',
    description: 'The project is complete.',
  },
];
