export const environment = {
  googleMapsApiKey: 'AIzaSyAnY5ho2u6fc7IM48MFg4f9KacWEe7WKw4',
  markerClusterImagePath:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  apiUrl: "",
  msalConfig: {
    auth: {
      clientId: '55ad1316-9663-470a-b009-5d0ee2a3a353',
      authority: 'https://login.microsoftonline.com/acde54a9-663e-43db-bc15-ceb81525619a/oauth2/v2.0/authorize',
      redirectUri: 'https://mobilitydb.kope.ai'
    },
    cache: {
      cacheLocation: "localStorage"
    },
    apiConfig: {
      scopes: ['api://55ad1316-9663-470a-b009-5d0ee2a3a353/User.Read']
    }
  }
};
