import { MenuItem } from '../models';

export const PROJECT_MENU_ITEM: MenuItem = {
  title: 'Project',
  divider: true,
  order: 0,
  menuItems: [
    {
      title: 'Mobility Dashboard',
      route: ['/dashboard'],
      icon: 'location',
      additionalClass: 'project-menu-item',
    },
    {
      title: 'Project Table',
      route: ['/projects'],
      icon: 'table--split',
      additionalClass: 'project-menu-item',
    },
    {
      title: 'Create New Project',
      route: ['/create-project'],
      icon: 'result--draft',
      additionalClass: 'project-menu-item',
    },
  ],
};

export const ADMIN_MENU_ITEM: MenuItem = {
  title: 'Admin Settings',
  divider: true,
  order: 2,
  menuItems: [
    {
      title: 'Project Templates',
      route: ['/admin'],
      icon: 'model-builder',
    },
    {
      title: 'User Management',
      route: ['/admin/users'],
      icon: 'folders',
    },
    {
      title: 'Control Panel',
      route: ['/admin/control-panel'],
      icon: 'settings',
    },
  ],
};

export function getDashboardMenuItem(): MenuItem {
  return {
    title: 'Dashboard',
    divider: true,
    order: 1,
    menuItems: [
      {
        title: 'Project Info',
        route: [],
        icon: 'model-builder',
      }
    ],
  };
}
