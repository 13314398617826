import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { PROJECTS_TABLE_ENDPOINT } from '../constants';
import {
  PaginationTablePageInformationQuery,
  ProjectTableCountDto,
  ProjectTableItem,
  ProjectTableLocationsDto,
  ProjectsTablePagination,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class ProjectsTableService {
  private _apiUrl = PROJECTS_TABLE_ENDPOINT;

  constructor(private apiService: ApiService) {}

  getProjectsTableData(projectsTablePagination: ProjectsTablePagination): Observable<ProjectTableItem[]> {
    const url = `${this._apiUrl}/table`;
    return this.apiService.get<ProjectTableItem[]>(url, projectsTablePagination);
  }

  getProjectCount(
    paginationTablePageInformationQuery: PaginationTablePageInformationQuery,
  ): Observable<ProjectTableCountDto> {
    const url = `${this._apiUrl}/count`;
    return this.apiService.get<ProjectTableCountDto>(url, paginationTablePageInformationQuery);
  }

  getProjectLocations(): Observable<ProjectTableLocationsDto> {
    const url = `${this._apiUrl}/locations`;
    return this.apiService.get<ProjectTableLocationsDto>(url, {});
  }
}
