import * as i0 from '@angular/core';
import { Injectable, Optional, SkipSelf, NgModule } from '@angular/core';
class ExperimentalService {
  constructor() {
    /**
     * Map to hold all our experiments
     */
    this.experiments = new Map();
  }
  /**
   * Adds an experiment if it doesn't exist.
   */
  addExperiment(name, options = {
    enabled: false
  }) {
    if (!this.experiments.has(name)) {
      this.experiments.set(name, options);
    }
  }
  /**
   * Enables an experiment by name
   * @param name name of the experiment to enable
   */
  enableExperiment(name) {
    const experiment = this.getExperiment(name);
    experiment.enabled = true;
  }
  /**
   * Disables an experiment by name
   * @param name name of the experiment to disable
   */
  disableExperiment(name) {
    const experiment = this.getExperiment(name);
    experiment.enabled = false;
  }
  /**
   * Get the options for an experiment by name
   * @param name name of experiment to get
   */
  getExperiment(name) {
    if (!this.experiments.has(name)) {
      this.addExperiment(name);
      return this.getExperiment(name);
    }
    return this.experiments.get(name);
  }
  /**
   * Get an array of tuples representing an experiment and it's options
   */
  getExperiments() {
    return Array.from(this.experiments.entries());
  }
}
ExperimentalService.ɵfac = function ExperimentalService_Factory(t) {
  return new (t || ExperimentalService)();
};
ExperimentalService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ExperimentalService,
  factory: ExperimentalService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExperimentalService, [{
    type: Injectable
  }], null, null);
})();

// either provides a new instance of ExperimentalService, or returns the parent
function EXPERIMENTAL_SERVICE_PROVIDER_FACTORY(parentService) {
  return parentService || new ExperimentalService();
}
const EXPERIMENTAL_SERVICE_PROVIDER = {
  provide: ExperimentalService,
  deps: [[new Optional(), new SkipSelf(), ExperimentalService]],
  useFactory: EXPERIMENTAL_SERVICE_PROVIDER_FACTORY
};
class ExperimentalModule {}
ExperimentalModule.ɵfac = function ExperimentalModule_Factory(t) {
  return new (t || ExperimentalModule)();
};
ExperimentalModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ExperimentalModule
});
ExperimentalModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ExperimentalService, EXPERIMENTAL_SERVICE_PROVIDER]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExperimentalModule, [{
    type: NgModule,
    args: [{
      providers: [ExperimentalService, EXPERIMENTAL_SERVICE_PROVIDER]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { EXPERIMENTAL_SERVICE_PROVIDER, EXPERIMENTAL_SERVICE_PROVIDER_FACTORY, ExperimentalModule, ExperimentalService };
