import * as i0 from '@angular/core';
import { Directive, Input, HostBinding, Component, EventEmitter, ViewChild, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from 'carbon-components-angular/tooltip';
import { TooltipModule } from 'carbon-components-angular/tooltip';

/**
 * A convenience directive for applying styling to a button. Get started with importing the module:
 *
 * ```typescript
 * import { ButtonModule } from 'carbon-components-angular';
 * ```
 *
 * Example:
 *
 * ```html
 * <button cdsButton>A button</button>
 * <button cdsButton="secondary">A secondary button</button>
 * ```
 *
 * See the [vanilla carbon docs](http://www.carbondesignsystem.com/components/button/code) for more detail.
 *
 * [See demo](../../?path=/story/components-button--basic)
 */
const _c0 = ["*"];
const _c1 = ["button"];
class Button {
  constructor() {
    /**
     * Sets the button type
     * Accepts `ButtonType` or nothing (empty string which is equivalent to "primary")
     * Empty string has been added as an option for Angular 16+ to resolve type errors
     */
    this.cdsButton = "primary";
    /**
     * Set to `true` for a skeleton state button
     */
    this.skeleton = false;
    /**
     * Set to `true` if the button contains only an icon
     * This should only be used for creating custom icon buttons, otherwise use
     * `<cds-icon-button></cds-icon-button>` component
     */
    this.iconOnly = false;
    /**
     * Set to `true` for a "expressive" style button
     */
    this.isExpressive = false;
    // a whole lot of HostBindings ... this way we don't have to touch the elementRef directly
    this.baseClass = true;
  }
  /**
   * @deprecated as of v5 - Use `cdsButton` input property instead
   */
  set ibmButton(type) {
    this.cdsButton = type;
  }
  get primaryButton() {
    return this.cdsButton === "primary" || !this.cdsButton;
  }
  get secondaryButton() {
    return this.cdsButton === "secondary";
  }
  get tertiaryButton() {
    return this.cdsButton === "tertiary";
  }
  get ghostButton() {
    return this.cdsButton === "ghost";
  }
  get dangerButton() {
    return this.cdsButton === "danger" || this.cdsButton === "danger--primary";
  }
  get dangerTertiary() {
    return this.cdsButton === "danger--tertiary";
  }
  get dangerGhost() {
    return this.cdsButton === "danger--ghost";
  }
  /**
   * @todo remove `cds--btn--${size}` classes in v12
   */
  get smallSize() {
    return this.size === "sm" && !this.isExpressive;
  }
  get mediumSize() {
    return this.size === "md" && !this.isExpressive;
  }
  get largeSize() {
    return this.size === "lg";
  }
  get extraLargeSize() {
    return this.size === "xl";
  }
  get twoExtraLargeSize() {
    return this.size === "2xl";
  }
  // Size classes
  get smallLayoutSize() {
    return this.size === "sm" && !this.isExpressive;
  }
  get mediumLayoutSize() {
    return this.size === "md" && !this.isExpressive;
  }
  get largeLayoutSize() {
    return this.size === "lg";
  }
  get extraLargeLayoutSize() {
    return this.size === "xl";
  }
  get twoExtraLargeLayoutSize() {
    return this.size === "2xl";
  }
}
Button.ɵfac = function Button_Factory(t) {
  return new (t || Button)();
};
Button.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: Button,
  selectors: [["", "cdsButton", ""], ["", "ibmButton", ""]],
  hostVars: 42,
  hostBindings: function Button_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--skeleton", ctx.skeleton)("cds--btn--icon-only", ctx.iconOnly)("cds--btn--expressive", ctx.isExpressive)("cds--btn", ctx.baseClass)("cds--btn--primary", ctx.primaryButton)("cds--btn--secondary", ctx.secondaryButton)("cds--btn--tertiary", ctx.tertiaryButton)("cds--btn--ghost", ctx.ghostButton)("cds--btn--danger", ctx.dangerButton)("cds--btn--danger--tertiary", ctx.dangerTertiary)("cds--btn--danger--ghost", ctx.dangerGhost)("cds--btn--sm", ctx.smallSize)("cds--btn--md", ctx.mediumSize)("cds--btn--lg", ctx.largeSize)("cds--btn--xl", ctx.extraLargeSize)("cds--btn--2xl", ctx.twoExtraLargeSize)("cds--layout--size-sm", ctx.smallLayoutSize)("cds--layout--size-md", ctx.mediumLayoutSize)("cds--layout--size-lg", ctx.largeLayoutSize)("cds--layout--size-xl", ctx.extraLargeLayoutSize)("cds--layout--size-2xl", ctx.twoExtraLargeLayoutSize);
    }
  },
  inputs: {
    ibmButton: "ibmButton",
    cdsButton: "cdsButton",
    size: "size",
    skeleton: "skeleton",
    iconOnly: "iconOnly",
    isExpressive: "isExpressive"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Button, [{
    type: Directive,
    args: [{
      selector: "[cdsButton], [ibmButton]"
    }]
  }], null, {
    ibmButton: [{
      type: Input
    }],
    cdsButton: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    skeleton: [{
      type: HostBinding,
      args: ["class.cds--skeleton"]
    }, {
      type: Input
    }],
    iconOnly: [{
      type: HostBinding,
      args: ["class.cds--btn--icon-only"]
    }, {
      type: Input
    }],
    isExpressive: [{
      type: HostBinding,
      args: ["class.cds--btn--expressive"]
    }, {
      type: Input
    }],
    baseClass: [{
      type: HostBinding,
      args: ["class.cds--btn"]
    }],
    primaryButton: [{
      type: HostBinding,
      args: ["class.cds--btn--primary"]
    }],
    secondaryButton: [{
      type: HostBinding,
      args: ["class.cds--btn--secondary"]
    }],
    tertiaryButton: [{
      type: HostBinding,
      args: ["class.cds--btn--tertiary"]
    }],
    ghostButton: [{
      type: HostBinding,
      args: ["class.cds--btn--ghost"]
    }],
    dangerButton: [{
      type: HostBinding,
      args: ["class.cds--btn--danger"]
    }],
    dangerTertiary: [{
      type: HostBinding,
      args: ["class.cds--btn--danger--tertiary"]
    }],
    dangerGhost: [{
      type: HostBinding,
      args: ["class.cds--btn--danger--ghost"]
    }],
    smallSize: [{
      type: HostBinding,
      args: ["class.cds--btn--sm"]
    }],
    mediumSize: [{
      type: HostBinding,
      args: ["class.cds--btn--md"]
    }],
    largeSize: [{
      type: HostBinding,
      args: ["class.cds--btn--lg"]
    }],
    extraLargeSize: [{
      type: HostBinding,
      args: ["class.cds--btn--xl"]
    }],
    twoExtraLargeSize: [{
      type: HostBinding,
      args: ["class.cds--btn--2xl"]
    }],
    smallLayoutSize: [{
      type: HostBinding,
      args: ["class.cds--layout--size-sm"]
    }],
    mediumLayoutSize: [{
      type: HostBinding,
      args: ["class.cds--layout--size-md"]
    }],
    largeLayoutSize: [{
      type: HostBinding,
      args: ["class.cds--layout--size-lg"]
    }],
    extraLargeLayoutSize: [{
      type: HostBinding,
      args: ["class.cds--layout--size-xl"]
    }],
    twoExtraLargeLayoutSize: [{
      type: HostBinding,
      args: ["class.cds--layout--size-2xl"]
    }]
  });
})();

/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { ButtonModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-button-button-set--basic)
 */
class ButtonSet {
  constructor() {
    this.buttonSetClass = true;
  }
}
ButtonSet.ɵfac = function ButtonSet_Factory(t) {
  return new (t || ButtonSet)();
};
ButtonSet.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ButtonSet,
  selectors: [["cds-button-set"], ["ibm-button-set"]],
  hostVars: 2,
  hostBindings: function ButtonSet_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--btn-set", ctx.buttonSetClass);
    }
  },
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function ButtonSet_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonSet, [{
    type: Component,
    args: [{
      selector: "cds-button-set, ibm-button-set",
      template: "<ng-content></ng-content>"
    }]
  }], null, {
    buttonSetClass: [{
      type: HostBinding,
      args: ["class.cds--btn-set"]
    }]
  });
})();

/**
 * Base button with common input properties for configuring icon button.
 * Extend class to inherit @Input meta data
 *
 * Used by pagination nav icon button, code snippet, etc.
 */
class BaseIconButton {
  constructor() {
    /**
     * Set to `false` to hide caret
     */
    this.caret = true;
    /**
     * Set to `false` to hide shadow
     */
    this.dropShadow = true;
    /**
     * Set to `true` to enable high contrast
     */
    this.highContrast = true;
    /**
     * Set to `true` to have the popover open by default
     */
    this.isOpen = false;
    /**
     * Set popover alignment
     */
    this.align = "bottom";
    /**
     * Set delay before tooltip is shown
     */
    this.enterDelayMs = 100;
    /**
     * Set delay when tooltip disappears
     */
    this.leaveDelayMs = 300;
  }
}
BaseIconButton.ɵfac = function BaseIconButton_Factory(t) {
  return new (t || BaseIconButton)();
};
BaseIconButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BaseIconButton,
  selectors: [["ng-component"]],
  inputs: {
    caret: "caret",
    dropShadow: "dropShadow",
    highContrast: "highContrast",
    isOpen: "isOpen",
    align: "align",
    enterDelayMs: "enterDelayMs",
    leaveDelayMs: "leaveDelayMs"
  },
  decls: 0,
  vars: 0,
  template: function BaseIconButton_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseIconButton, [{
    type: Component,
    args: [{
      template: ""
    }]
  }], null, {
    caret: [{
      type: Input
    }],
    dropShadow: [{
      type: Input
    }],
    highContrast: [{
      type: Input
    }],
    isOpen: [{
      type: Input
    }],
    align: [{
      type: Input
    }],
    enterDelayMs: [{
      type: Input
    }],
    leaveDelayMs: [{
      type: Input
    }]
  });
})();

/**
 * Get started with importing the module:
 *
 * ```typescript
 * import { ButtonModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-button-icon-button--basic)
 */
class IconButton extends BaseIconButton {
  constructor(renderer) {
    super();
    this.renderer = renderer;
    /**
     * Override id
     */
    this.buttonId = `icon-btn-${IconButton.iconButtonCounter++}`;
    /**
     * Sets the button type.
     */
    this.kind = "primary";
    /**
     * Specify the size of the button.
     */
    this.size = "lg";
    /**
     * Set button type, `button` by default
     */
    this.type = "button";
    /**
     * Set to `true` to make button expressive
     */
    this.isExpressive = false;
    /**
     * Set to `true` to disable button
     */
    this.disabled = false;
    /**
     * Common button events
     */
    this.click = new EventEmitter();
    this.focus = new EventEmitter();
    this.blur = new EventEmitter();
    /**
     * Event to emit when click event is fired from tooltip
     */
    this.tooltipClick = new EventEmitter();
    this.classList = {};
    this.attributeList = {};
  }
  /**
   * Pass global carbon classes to icon button
   */
  set buttonNgClass(obj) {
    this.classList = Object.assign({
      "cds--btn--disabled": this.disabled
    }, obj);
  }
  get buttonNgClass() {
    return this.classList;
  }
  /**
   * @param obj: { [key: string]: string
   * User can pass additional button attributes if component property does not already exist
   * Key is the attribute name & value is the attribute value for the button
   */
  set buttonAttributes(obj) {
    if (this.button) {
      // Remove old attributes
      Object.keys(this.attributeList).forEach(key => {
        this.renderer.removeAttribute(this.button.nativeElement, key);
      });
      // Set new attributes
      Object.keys(obj).forEach(key => {
        this.renderer.setAttribute(this.button.nativeElement, key, obj[key]);
      });
    }
    // Set new attributes
    this.attributeList = obj;
  }
  get buttonAttributes() {
    return this.buttonAttributes;
  }
  ngAfterViewInit() {
    // Set attributes once element is found
    this.buttonAttributes = this.attributeList;
  }
  /**
   * Stop propogation of click event
   * Else double fires (click) event
   */
  emitClickEvent(event, element = "button") {
    event.preventDefault();
    event.stopPropagation();
    // Prevents (click) event from bubbling since it would appear user clicked the `button`
    if (element === "tooltip") {
      this.tooltipClick.emit(event);
      return;
    }
    this.click.emit(event);
  }
}
IconButton.iconButtonCounter = 0;
IconButton.ɵfac = function IconButton_Factory(t) {
  return new (t || IconButton)(i0.ɵɵdirectiveInject(i0.Renderer2));
};
IconButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: IconButton,
  selectors: [["cds-icon-button"], ["ibm-icon-button"]],
  viewQuery: function IconButton_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.button = _t.first);
    }
  },
  inputs: {
    buttonNgClass: "buttonNgClass",
    buttonAttributes: "buttonAttributes",
    buttonId: "buttonId",
    kind: "kind",
    size: "size",
    type: "type",
    isExpressive: "isExpressive",
    disabled: "disabled",
    description: "description"
  },
  outputs: {
    click: "click",
    focus: "focus",
    blur: "blur",
    tooltipClick: "tooltipClick"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 4,
  vars: 17,
  consts: [[1, "cds--icon-tooltip", 3, "description", "disabled", "caret", "dropShadow", "highContrast", "isOpen", "align", "enterDelayMs", "leaveDelayMs", "click"], [3, "id", "disabled", "iconOnly", "ngClass", "cdsButton", "size", "isExpressive", "click", "focus", "blur"], ["button", ""]],
  template: function IconButton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "cds-tooltip", 0);
      i0.ɵɵlistener("click", function IconButton_Template_cds_tooltip_click_0_listener($event) {
        return ctx.emitClickEvent($event, "tooltip");
      });
      i0.ɵɵelementStart(1, "button", 1, 2);
      i0.ɵɵlistener("click", function IconButton_Template_button_click_1_listener($event) {
        return ctx.emitClickEvent($event);
      })("focus", function IconButton_Template_button_focus_1_listener($event) {
        return ctx.focus.emit($event);
      })("blur", function IconButton_Template_button_blur_1_listener($event) {
        return ctx.blur.emit($event);
      });
      i0.ɵɵprojection(3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("description", ctx.description)("disabled", ctx.disabled)("caret", ctx.caret)("dropShadow", ctx.dropShadow)("highContrast", ctx.highContrast)("isOpen", ctx.isOpen)("align", ctx.align)("enterDelayMs", ctx.enterDelayMs)("leaveDelayMs", ctx.leaveDelayMs);
      i0.ɵɵadvance();
      i0.ɵɵproperty("id", ctx.buttonId)("disabled", ctx.disabled)("iconOnly", true)("ngClass", ctx.buttonNgClass)("cdsButton", ctx.kind)("size", ctx.size)("isExpressive", ctx.isExpressive);
      i0.ɵɵattribute("type", ctx.type);
    }
  },
  dependencies: [i1.NgClass, i2.Tooltip, Button],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconButton, [{
    type: Component,
    args: [{
      selector: "cds-icon-button, ibm-icon-button",
      template: `
	<cds-tooltip
		class="cds--icon-tooltip"
		[description]="description"
		[disabled]="disabled"
		[caret]="caret"
		[dropShadow]="dropShadow"
		[highContrast]="highContrast"
		[isOpen]="isOpen"
		[align]="align"
		[enterDelayMs]="enterDelayMs"
		[leaveDelayMs]="leaveDelayMs"
		(click)="emitClickEvent($event, 'tooltip')">
		<button
			#button
			[id]="buttonId"
			[disabled]="disabled"
			[attr.type]="type"
			[iconOnly]="true"
			[ngClass]="buttonNgClass"
			[cdsButton]="kind"
			[size]="size"
			[isExpressive]="isExpressive"
			(click)="emitClickEvent($event)"
			(focus)="focus.emit($event)"
			(blur)="blur.emit($event)">
			<ng-content></ng-content>
		</button>
	</cds-tooltip>
	`
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }];
  }, {
    buttonNgClass: [{
      type: Input
    }],
    buttonAttributes: [{
      type: Input
    }],
    button: [{
      type: ViewChild,
      args: ["button"]
    }],
    buttonId: [{
      type: Input
    }],
    kind: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    isExpressive: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    description: [{
      type: Input
    }],
    click: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    blur: [{
      type: Output
    }],
    tooltipClick: [{
      type: Output
    }]
  });
})();
class ButtonModule {}
ButtonModule.ɵfac = function ButtonModule_Factory(t) {
  return new (t || ButtonModule)();
};
ButtonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ButtonModule
});
ButtonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, TooltipModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonModule, [{
    type: NgModule,
    args: [{
      declarations: [Button, ButtonSet, BaseIconButton, IconButton],
      exports: [Button, ButtonSet, IconButton],
      imports: [CommonModule, TooltipModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BaseIconButton, Button, ButtonModule, ButtonSet, IconButton };
