import * as i0 from '@angular/core';
import { EventEmitter, Directive, HostBinding, Output, Input, Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Applies popover container styling to the element it is applied to. Get started with importing the module:
 *
 * ```typescript
 * import { PopoverModule } from 'carbon-components-angular';
 * ```
 *
 * [See demo](../../?path=/story/components-popover--basic)
 */
const _c0 = ["*"];
class PopoverContainer {
  constructor() {
    /**
     * Emits an event when the dialog is closed
     */
    this.onClose = new EventEmitter();
    /**
     * Emits an event when the dialog is opened
     */
    this.onOpen = new EventEmitter();
    /**
     * Emits an event when the state of `isOpen` changes. Allows `isOpen` to be double bound
     */
    this.isOpenChange = new EventEmitter();
    this.caret = true;
    this.dropShadow = true;
    this.highContrast = false;
    this.isOpen = false;
    this.containerClass = true;
    this.align = "bottom";
  }
  // Top
  get alignmentTopClass() {
    return this.align === "top";
  }
  get alignmentTopLeftClass() {
    return this.align === "top-left";
  }
  get alignmentTopRightClass() {
    return this.align === "top-right";
  }
  // Bottom
  get alignmentBottomClass() {
    return this.align === "bottom";
  }
  get alignmentBottomLeftClass() {
    return this.align === "bottom-left";
  }
  get alignmentBottomRightClass() {
    return this.align === "bottom-right";
  }
  // Left
  get alignmentLeftClass() {
    return this.align === "left";
  }
  get alignmentLeftTopClass() {
    return this.align === "left-top";
  }
  get alignmentLeftBottomClass() {
    return this.align === "left-bottom";
  }
  // Right
  get alignmentRightClass() {
    return this.align === "right";
  }
  get alignmentRightTopClass() {
    return this.align === "right-top";
  }
  get alignmentRightBottomClass() {
    return this.align === "right-bottom";
  }
  handleChange(open, event) {
    if (this.isOpen !== open) {
      this.isOpenChange.emit(open);
    }
    if (open) {
      this.onOpen.emit(event);
    } else {
      this.onClose.emit(event);
    }
    this.isOpen = open;
  }
}
PopoverContainer.ɵfac = function PopoverContainer_Factory(t) {
  return new (t || PopoverContainer)();
};
PopoverContainer.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PopoverContainer,
  selectors: [["", "cdsPopover", ""], ["", "ibmPopover", ""]],
  hostVars: 34,
  hostBindings: function PopoverContainer_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--popover--top", ctx.alignmentTopClass)("cds--popover--top-left", ctx.alignmentTopLeftClass)("cds--popover--top-right", ctx.alignmentTopRightClass)("cds--popover--bottom", ctx.alignmentBottomClass)("cds--popover--bottom-left", ctx.alignmentBottomLeftClass)("cds--popover--bottom-right", ctx.alignmentBottomRightClass)("cds--popover--left", ctx.alignmentLeftClass)("cds--popover--left-top", ctx.alignmentLeftTopClass)("cds--popover--left-bottom", ctx.alignmentLeftBottomClass)("cds--popover--right", ctx.alignmentRightClass)("cds--popover--right-top", ctx.alignmentRightTopClass)("cds--popover--right-bottom", ctx.alignmentRightBottomClass)("cds--popover--caret", ctx.caret)("cds--popover--drop-shadow", ctx.dropShadow)("cds--popover--high-contrast", ctx.highContrast)("cds--popover--open", ctx.isOpen)("cds--popover-container", ctx.containerClass);
    }
  },
  inputs: {
    caret: "caret",
    dropShadow: "dropShadow",
    highContrast: "highContrast",
    isOpen: "isOpen",
    align: "align"
  },
  outputs: {
    onClose: "onClose",
    onOpen: "onOpen",
    isOpenChange: "isOpenChange"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverContainer, [{
    type: Directive,
    args: [{
      selector: "[cdsPopover], [ibmPopover]"
    }]
  }], null, {
    alignmentTopClass: [{
      type: HostBinding,
      args: ["class.cds--popover--top"]
    }],
    alignmentTopLeftClass: [{
      type: HostBinding,
      args: ["class.cds--popover--top-left"]
    }],
    alignmentTopRightClass: [{
      type: HostBinding,
      args: ["class.cds--popover--top-right"]
    }],
    alignmentBottomClass: [{
      type: HostBinding,
      args: ["class.cds--popover--bottom"]
    }],
    alignmentBottomLeftClass: [{
      type: HostBinding,
      args: ["class.cds--popover--bottom-left"]
    }],
    alignmentBottomRightClass: [{
      type: HostBinding,
      args: ["class.cds--popover--bottom-right"]
    }],
    alignmentLeftClass: [{
      type: HostBinding,
      args: ["class.cds--popover--left"]
    }],
    alignmentLeftTopClass: [{
      type: HostBinding,
      args: ["class.cds--popover--left-top"]
    }],
    alignmentLeftBottomClass: [{
      type: HostBinding,
      args: ["class.cds--popover--left-bottom"]
    }],
    alignmentRightClass: [{
      type: HostBinding,
      args: ["class.cds--popover--right"]
    }],
    alignmentRightTopClass: [{
      type: HostBinding,
      args: ["class.cds--popover--right-top"]
    }],
    alignmentRightBottomClass: [{
      type: HostBinding,
      args: ["class.cds--popover--right-bottom"]
    }],
    onClose: [{
      type: Output
    }],
    onOpen: [{
      type: Output
    }],
    isOpenChange: [{
      type: Output
    }],
    caret: [{
      type: HostBinding,
      args: ["class.cds--popover--caret"]
    }, {
      type: Input
    }],
    dropShadow: [{
      type: HostBinding,
      args: ["class.cds--popover--drop-shadow"]
    }, {
      type: Input
    }],
    highContrast: [{
      type: HostBinding,
      args: ["class.cds--popover--high-contrast"]
    }, {
      type: Input
    }],
    isOpen: [{
      type: HostBinding,
      args: ["class.cds--popover--open"]
    }, {
      type: Input
    }],
    containerClass: [{
      type: HostBinding,
      args: ["class.cds--popover-container"]
    }],
    align: [{
      type: Input
    }]
  });
})();

/**
 * [See demo](../../?path=/story/components-popover--basic)
 */
class PopoverContent {
  constructor() {
    this.popoverClass = true;
  }
}
PopoverContent.ɵfac = function PopoverContent_Factory(t) {
  return new (t || PopoverContent)();
};
PopoverContent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PopoverContent,
  selectors: [["cds-popover-content"], ["ibm-popover-content"]],
  hostVars: 2,
  hostBindings: function PopoverContent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("cds--popover", ctx.popoverClass);
    }
  },
  ngContentSelectors: _c0,
  decls: 3,
  vars: 0,
  consts: [[1, "cds--popover-content"], [1, "cds--popover-caret"]],
  template: function PopoverContent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "span", 0);
      i0.ɵɵprojection(1);
      i0.ɵɵelementEnd();
      i0.ɵɵelement(2, "span", 1);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverContent, [{
    type: Component,
    args: [{
      selector: "cds-popover-content, ibm-popover-content",
      template: `
		<span class="cds--popover-content">
			<ng-content></ng-content>
		</span>
		<span class="cds--popover-caret"></span>
	`
    }]
  }], null, {
    popoverClass: [{
      type: HostBinding,
      args: ["class.cds--popover"]
    }]
  });
})();
class PopoverModule {}
PopoverModule.ɵfac = function PopoverModule_Factory(t) {
  return new (t || PopoverModule)();
};
PopoverModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PopoverModule
});
PopoverModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverModule, [{
    type: NgModule,
    args: [{
      declarations: [PopoverContainer, PopoverContent],
      exports: [PopoverContainer, PopoverContent],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PopoverContainer, PopoverContent, PopoverModule };
