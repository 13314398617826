/**
 * Utilites to manipulate the position of elements relative to other elements
 */
var _a;
export var PLACEMENTS;
(function (PLACEMENTS) {
    PLACEMENTS["LEFT"] = "left";
    PLACEMENTS["RIGHT"] = "right";
    PLACEMENTS["TOP"] = "top";
    PLACEMENTS["BOTTOM"] = "bottom";
})(PLACEMENTS || (PLACEMENTS = {}));
export var defaultPositions = (_a = {},
    _a[PLACEMENTS.LEFT] = function (referenceOffset, target, referenceRect) { return ({
        top: referenceOffset.top - Math.round(target.offsetHeight / 2) + Math.round(referenceRect.height / 2),
        left: Math.round(referenceOffset.left - target.offsetWidth)
    }); },
    _a[PLACEMENTS.RIGHT] = function (referenceOffset, target, referenceRect) { return ({
        top: referenceOffset.top - Math.round(target.offsetHeight / 2) + Math.round(referenceRect.height / 2),
        left: Math.round(referenceOffset.left + referenceRect.width)
    }); },
    _a[PLACEMENTS.TOP] = function (referenceOffset, target, referenceRect) { return ({
        top: Math.round(referenceOffset.top - target.offsetHeight),
        left: referenceOffset.left - Math.round(target.offsetWidth / 2) + Math.round(referenceRect.width / 2)
    }); },
    _a[PLACEMENTS.BOTTOM] = function (referenceOffset, target, referenceRect) { return ({
        top: Math.round(referenceOffset.top + referenceRect.height),
        left: referenceOffset.left - Math.round(target.offsetWidth / 2) + Math.round(referenceRect.width / 2)
    }); },
    _a);
var windowRef = typeof window !== "undefined" ? window : {
    innerHeight: 0,
    scrollY: 0,
    innerWidth: 0,
    scrollX: 0
};
var Position = /** @class */ (function () {
    function Position(positions) {
        if (positions === void 0) { positions = {}; }
        this.positions = defaultPositions;
        this.positions = Object.assign({}, defaultPositions, positions);
    }
    Position.prototype.getRelativeOffset = function (target) {
        // start with the initial element offsets
        var offsets = {
            left: target.offsetLeft,
            top: target.offsetTop
        };
        // get each static (i.e. not absolute or relative) offsetParent and sum the left/right offsets
        while (target.offsetParent && getComputedStyle(target.offsetParent).position === "static") {
            offsets.left += target.offsetLeft;
            offsets.top += target.offsetTop;
            target = target.offsetParent;
        }
        return offsets;
    };
    Position.prototype.getAbsoluteOffset = function (target) {
        var currentNode = target;
        var margins = {
            top: 0,
            left: 0
        };
        // searches for containing elements with additional margins
        while (currentNode.offsetParent) {
            var computed = getComputedStyle(currentNode.offsetParent);
            // find static elements with additional margins
            // since they tend to throw off our positioning
            // (usually this is just the body)
            if (computed.position === "static" &&
                computed.marginLeft &&
                computed.marginTop) {
                if (parseInt(computed.marginTop, 10)) {
                    margins.top += parseInt(computed.marginTop, 10);
                }
                if (parseInt(computed.marginLeft, 10)) {
                    margins.left += parseInt(computed.marginLeft, 10);
                }
            }
            currentNode = currentNode.offsetParent;
        }
        var targetRect = target.getBoundingClientRect();
        var relativeRect = document.body.getBoundingClientRect();
        return {
            top: targetRect.top - relativeRect.top + margins.top,
            left: targetRect.left - relativeRect.left + margins.left
        };
    };
    // finds the position relative to the `reference` element
    Position.prototype.findRelative = function (reference, target, placement) {
        var referenceOffset = this.getRelativeOffset(reference);
        var referenceRect = reference.getBoundingClientRect();
        return this.calculatePosition(referenceOffset, referenceRect, target, placement);
    };
    Position.prototype.findAbsolute = function (reference, target, placement) {
        var referenceOffset = this.getAbsoluteOffset(reference);
        var referenceRect = reference.getBoundingClientRect();
        return this.calculatePosition(referenceOffset, referenceRect, target, placement);
    };
    Position.prototype.findPosition = function (reference, target, placement, offsetFunction) {
        if (offsetFunction === void 0) { offsetFunction = this.getAbsoluteOffset.bind(this); }
        var referenceOffset = offsetFunction(reference);
        var referenceRect = reference.getBoundingClientRect();
        return this.calculatePosition(referenceOffset, referenceRect, target, placement);
    };
    Position.prototype.findPositionAt = function (offset, target, placement) {
        return this.calculatePosition(offset, { top: 0, left: 0, height: 0, width: 0 }, target, placement);
    };
    /**
     * Get the dimensions of an element from an AbsolutePosition and a reference element
     */
    Position.prototype.getPlacementBox = function (target, position) {
        var targetBottom = target.offsetHeight + position.top;
        var targetRight = target.offsetWidth + position.left;
        return {
            top: position.top,
            bottom: targetBottom,
            left: position.left,
            right: targetRight
        };
    };
    Position.prototype.addOffset = function (position, top, left) {
        if (top === void 0) { top = 0; }
        if (left === void 0) { left = 0; }
        return Object.assign({}, position, {
            top: position.top + top,
            left: position.left + left
        });
    };
    Position.prototype.setElement = function (element, position) {
        element.style.top = position.top + "px";
        element.style.left = position.left + "px";
    };
    Position.prototype.findBestPlacement = function (reference, target, placements, containerFunction, positionFunction) {
        var _this = this;
        if (containerFunction === void 0) { containerFunction = this.defaultContainerFunction.bind(this); }
        if (positionFunction === void 0) { positionFunction = this.findPosition.bind(this); }
        /**
         * map over the array of placements and weight them based on the percentage of visible area
         * where visible area is defined as the area not obscured by the window borders
         */
        var weightedPlacements = placements.map(function (placement) {
            var pos = positionFunction(reference, target, placement);
            var box = _this.getPlacementBox(target, pos);
            var hiddenHeight = 0;
            var hiddenWidth = 0;
            var container = containerFunction();
            // the element is exceeding from top or bottom of its container
            if (box.top < container.top) {
                hiddenHeight = container.top - box.top;
            }
            else if (box.bottom > container.height) {
                hiddenHeight = box.bottom - container.height;
            }
            // the element is exceeding from left or right of its container
            if (box.left < container.left) {
                hiddenWidth = container.left - box.left;
            }
            else if (box.right > container.width) {
                hiddenWidth = box.right - container.width;
            }
            // if one of the hidden dimensions is 0 but the other is > 0
            // we want to have a positive area, so setting the null one to 1
            if (hiddenHeight && !hiddenWidth) {
                hiddenWidth = 1;
            }
            else if (hiddenWidth && !hiddenHeight) {
                hiddenHeight = 1;
            }
            var area = target.offsetHeight * target.offsetWidth;
            var hiddenArea = hiddenHeight * hiddenWidth;
            // if visibleArea is 0 it means the element is fully outside container bounds
            // and visiblePercent will then be 0
            var visibleArea = area - hiddenArea;
            var visiblePercent = visibleArea / area;
            return {
                placement: placement,
                weight: visiblePercent
            };
        });
        // sort the placements from best to worst
        weightedPlacements.sort(function (a, b) { return b.weight - a.weight; });
        // pick the best!
        return weightedPlacements[0].placement;
    };
    Position.prototype.findBestPlacementAt = function (offset, target, placements, containerFunction) {
        var _this = this;
        if (containerFunction === void 0) { containerFunction = this.defaultContainerFunction.bind(this); }
        var positionAt = function (_, target, placement) {
            return _this.findPositionAt(offset, target, placement);
        };
        return this.findBestPlacement(null, target, placements, containerFunction, positionAt);
    };
    Position.prototype.defaultContainerFunction = function () {
        return {
            // we go with window here, because that's going to be the simple/common case
            top: 0,
            left: 0,
            height: windowRef.innerHeight,
            width: windowRef.innerWidth
        };
    };
    Position.prototype.calculatePosition = function (referenceOffset, referenceRect, target, placement) {
        if (this.positions[placement]) {
            return this.positions[placement](referenceOffset, target, referenceRect);
        }
        console.error("No function found for placement, defaulting to 0,0");
        return { left: 0, top: 0 };
    };
    return Position;
}());
export { Position };
export var position = new Position();
export default Position;
